@media (max-width: 1200px) {
    body {
        &.navigation-open {
            &:after {
                content: '';
                display: block;
                position: fixed;
                background: rgba(black, .5);
                right: 0;
                left: 0;
                bottom: 0;
                top: 0;
                z-index: 1020;
            }

            .navigation {
                display: block;
            }

            .navigation-open {
                padding: 2em 0 0 1em;
            }
        }
    }
    .navigation {
        display: none;
        position: fixed;
        bottom: 0;
        top: 0;
        left: 0;
        z-index: 1021;
    }
    .chat {
        .no-message {
            display: none;
        }
    }
    .navigation + .sidebar-group {
        flex: 1;

        .sidebar {
            width: 100% !important;
        }
    }
    .chat {
        display: none !important;

        &.open {
            display: flex !important;
            position: fixed;
            background: white;
            bottom: 0;
            left: 0;
            top: 0;
            right: 0;
        }

        & + .sidebar-group {
            position: fixed;
            right: -50%;
            top: 0;
            bottom: 0;
            width: 30%;
            opacity: 0;
            transition: all .2s;
            box-shadow: 25px 0px 30px 5px black;
            visibility: hidden;
            z-index: 1021;

            .sidebar {
                width: 100% !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
                border-radius: 0 !important;
            }

            &.mobile-open {
                opacity: 1;
                right: 0;
                visibility: visible;
            }
        }
    }
    .layout .content {
        .chat .chat-footer {
            padding-bottom: 17px;
        }

        .sidebar-group .sidebar .list-group-item .users-list-body {
            .users-list-action {
                display: flex;
                flex-direction: row-reverse;

                .new-message-count {
                    margin-left: 10px;
                }

                .action-toggle {
                    top: 25px;
                    display: block;
                }
            }
        }

    }
    body.modal-open {
        .sidebar-group {
            right: 0;
        }
    }

    .liveChatAdmin {
        padding-top: 3em;

        .liveChatAdmin__asideMenu {
            position: fixed;
            top: 0;
            right: 0;
            background-color: white;
            height: 100vh;
        }
    }

    .pageLoadingSpinner {
        margin-left: 0;
    }
}

@media screen and (max-width: 992px) {
    body:not(.rtl), body {
        .sidebar-group {
            width: 45%;
        }
    }
}

@media screen and (max-width: 768px) {

    body:not(.rtl), body {
        .sidebar-group {
            width: 75%;

            .sidebar {
                .list-group-item {
                    .users-list-body h5 {
                        
                        font-size: $default-font-size + 7 !important;
                    }
                }
            }
        }
    }

    .layout {
        .content {

            .chat {
                .chat-header {
                    display: block !important;
                    padding-bottom: 15px !important;

                    .chat-header-user {
                        h5 {
                            font-size: $default-font-size + 5 !important;
                        }
                    }

                    .chat-header-action {
                        margin-top: 15px;
                    }
                }

                .chat-body {
                    .messages {
                        .message-item {
                            max-width: 100% !important;
                        }
                    }
                }
            }
        }
    }


    .BotBuilderSelection {
        header {
            flex-direction: column;

        }
    }

    .homePage .homePage__chatSection .homePage__chatContainer {
        width: 100%;
    }

}

@media screen and (max-width: 576px) {
    body:not(.rtl), body {
        .sidebar-group {
            width: 100%;
        }
    }

    body.form-membership {
        padding: 20px;

        .form-wrapper {
            padding-right: 20px;
            padding-left: 20px;
            width: 100% !important;
            margin-bottom: 20px;
        }
    }

    .BotBuilderSelection {
        header {
            div {
                flex-direction: column;

                a,input {
                    margin: 0.4em 0 !important;
                    text-align: center;
                }
            }
        }

        .krino__table {
            tr {
                display: flex;
                flex-direction: column;
                text-align: center;                
            }
        }
    }


    .chatsOnHold__section .chatsOnHold__list .chatsOnHold__chat {
        text-align: center;
        flex-direction: column;
        overflow: hidden;
        .chatsOnHold__chat__icon, .chatsOnHold__chat__infoContainer {
            position: static;
            transform: none;
        }

        .chatsOnHold__chat__title {
            margin: 0.5em 0;
        }
    }
}

@media screen and (max-width: 420px) {

    body:not(.rtl), body {
        .sidebar-group {
            width: 75%;

            .sidebar {
                .list-group-item {
                    .users-list-body h5 {
                        
                        font-size: $default-font-size + 2 !important;
                    }
                }
            }
        }
    }
}