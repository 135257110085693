.krino__tableSection {

  .title {
    color: $color-blue-secondary;
    font-weight: 500;
  }
  
  .description {
    font-size: 1.1em;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
  }

  .krino__table {
    overflow: hidden;
    width: 100%;
    height: 10em;
  
    thead {
      width: 50px;
      margin: 2em 0 ;
    }
  
    tbody {
      .clickable {
        cursor: pointer;
      }

      tr {
      
        &:nth-child(odd) {
          background-color: #308ecb36;
        }
      }
    }
  
    th {
      padding: 1em;
    }
  
    td {
      padding: 1em;
    }
  }
}

.krino__button {
  background-color: $color-primary;
  color: white !important;
  padding: 0.5em 1em;
  border-radius: 30px;
  border: 0;
}

.krino__button--center {
  display: block;
  margin: 0 auto;
}

.krino__button--error {
  background-color: $color-danger;
}

.krino__button--disable, .krino__button:disabled {
  background-color: $color-krino-grey2;
  color: rgba($color: #000000, $alpha: 0.8) !important;
}

.krino__box {
  background-color: $color-krino-grey;
  box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
  border-radius: 30px;
}

.krino__input {
  border-radius: 2em;
  display: block;
  border: 0;
  box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
  font-size: 1.2em;
  padding: 0.3em;
  text-align: center;
}

.krino__input--2 {
  border: 0;
  background-color: transparent;
  outline: none;
  border-bottom: 0.2em solid rgba(32, 65, 145, 0.8);
  border-radius: 0.4em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.4em;
  width: 100%;
  text-align: center;
}

.krino__input::-webkit-outer-spin-button, .krino__input--2::-webkit-outer-spin-button,
.krino__input::-webkit-inner-spin-button, .krino__input--2::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.krino__input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.krino__backgroundBanner {
  position: absolute;
  background-color: $color-blue-secondary;
  background: linear-gradient(to left, $color-blue-secondary, $color-primary);
  width: 100vw;
  height: 20em;
  top: 0;
  left: 0;
}

.krino__backgroundBanner--solid {
  background-color: $color-blue-secondary;
  background: linear-gradient(to left, $color-blue-secondary, $color-primary);
  width: 100%;
  height: 20em;
  top: 0;
  left: 0;
}

.krino__backgroundBanner--full {
  height: 100%;
}

.krino__title {
  color: $color-primary;
  font-weight: 500;
}

.krino__subTitle {
  color: $color-blue-secondary;
  font-weight: 500;
}

.krino__title--center, .krino__subTitle--center {
  text-align: center;
}

.krino__modal {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

  .formContainer {
    margin: 2em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  
    .inputConainer {
      margin: 1em 0.5em;
    }
  }

	.title {
		margin: 0;
	}

	.inputConainer {
		height: 3em;
		margin: 2em 0;
    
    p {
      margin: 0;
      margin-bottom: 0.3em;
      color: $color-primary;
    }

		label {
			width: 3em;
			margin: 0 auto !important;
		}
	}
	.infoContainer {
		width: 100%;
		margin-top: 3em;
		margin-bottom: 2em;
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		button, a {
			margin-bottom: 1em;
		}
	}

	.errorMessage {
		color: $color-danger;
	}
}


// form generico
.krino_formField {
  margin-bottom: 0.5em;

  .krino_label {
    font-weight: 500;
    margin: 0.25em;
    color: $color-blue-secondary;
  }
}

.krino_bold_medium {
  font-weight: 500;
}


/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .krino_scrollbar {
    scrollbar-width: auto;
    scrollbar-color: #716f71 transparent;
  }

  /* Chrome, Edge, and Safari */
  .krino_scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    cursor: pointer;
  }
  
  .krino_scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .krino_scrollbar::-webkit-scrollbar-thumb {
    background-color: #716f71;
    border-radius: 10px;
    border: 3px solid transparent;
  }

  .krino_scrollbar--white {
    scrollbar-color: white transparent;
  }

  .krino_scrollbar--white::-webkit-scrollbar-thumb {
    background-color: white;
  }