.profile {
  padding: 1em;
  width: 100%;
  header {
    .krino__title {
      margin-left: 1em;
      margin-bottom: 1em;
    }
  }

  .inviteSection {
    width: 100%;
    min-width: 20em;
    margin: 0.2em;

    .inviteSection__title {
      color: $color-primary;
      
    }

    .inviteSection__form {
      margin: 5em auto;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 55em;

      label {
        P {
          font-size: 1.2em;
          font-weight: 500;
          text-align: center;
          color: $color-blue-secondary;
          margin: 0;
        }

        input {
          height: 3em;
          width: 80%;
          border-radius: 0.4em;
          margin: 1em auto;
        }
      }

      button {
        width: 50%;
        margin: 0 auto;
        border-radius: 0.4em;

        &:hover {
          transform: scale(1.03);
        }
      }

    }

  }

  .numberSection {
    padding: 1em 0 2em 0;
  }

  .buyNumberSection {
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $color-primary;
      margin: 1em 0;

      div:first-of-type {
        display: flex;
        align-items: center;
      }

      .numberSection__title {
        margin: 0;
      }
    }
    
    .numberToBuyContainer {
      width: 100%;
      padding: 2em;

      .numberToBuyList {
        padding: 0;
        list-style: none;

        li {
          padding: 1.5em 1em;
          box-shadow: $krino-boxShadow;
          margin-bottom: 0.5em;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: white;
   
          div {
            display: flex;
            align-items: center;

            p {
              margin: 0;
              margin-right: 1em;
              align-items: center;
            }
          }
        }
      }
    }

  }

  .scheduleContainer {
    // padding: 0 4em;

    .scheduleContainer__infoContainer {
      margin: 0 auto;
      margin-bottom: 1em;

      .scheduleContainer__title {
        color: $color-primary;
        // font-size: 2.5em;
      }
      .scheduleContainer__description {
        font-size: 1.1em;
      }
    }
  }

  .EditProfileContainer {
    // padding: 0 4em;

    .EditProfileContainer__infoContainer {
      margin: 0 auto;
      margin-bottom: 1em;

      .EditProfileContainer__title {
        color: $color-primary;
        // font-size: 2.5em;
      }

      .EditProfileContainer__description {
        font-size: 1.1em;
      }
    }

    .EditProfileContainer__form {
      margin: 1em auto;
      width: 100%;
      

      .inputContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }

      button {
        margin: 0 auto;
        display: block;
      }

      label {
        margin: 2em;
  
        p {
          font-weight: 500;
          color: $color-blue-secondary;
          margin: 0;
        }
  
        input, select {
          margin: 0;
          border: 0;
          box-shadow: $krino-boxShadow;
          background-color: white;
          border-radius: 0.4em;
          padding: 1em 3em;
          font-size: 1em;
        }
  
        input[type=number] {
          -moz-appearance: textfield;
          appearance: textfield;
        }
  
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          appearance: none;
          margin: 0;
        }
      }
    }
  }
}