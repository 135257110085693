.PromptConstructor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .PromptConstructor__title {
    font-size: 2em;
    color: $color-blue-secondary;
    text-align: center;
    margin-bottom: 0.8em;
  }


  .PromptConstructor__changeConfigButton {
    position: absolute;
    top: 0em;
    right: 1em;
  }

  .PromptConstructor__mainInputContainer {
    flex: 1 1 auto;
    width: 80%;
    margin: 0 auto;
    height: 100%;
    box-shadow: $krino-boxShadow;
    border: $color-krino-grey2 solid 1px;
    border-radius: 0.4em;
  }



  .PromptConstructor__textinput {
    border-radius: 0.4em;
    font-weight: 400;
    font-size: 1.2em;
    padding: 0.3em;
    resize: none;
    border: 0;
    width: 100%;
    height: calc(100% - 3em);
    outline: none;
  }

  .PromptConstructor__footer {
    height: 3em;
    border-top: $color-krino-grey2 solid 1px;
    padding: 0.5em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: 500;
      margin: 0;
    }

    .PromptConstructor__footer__errorMessage {
      color: $color-danger;
      font-weight: 600;
    }

    .PromptConstructor__footer__buttonsContainer {
      display: flex;

      button {
        background-color: $color-primary;
        border: 0;
        border-radius: 0.4em;
        color: white;
        text-align: center;
        padding: 0.2em 0.5em;
        margin: 0 0.5em;
      }
      
      button:hover {
        scale: 1.05;
        box-shadow: $krino-boxShadow;
      }

      .PromptConstructor__footer__selector {
        margin: 0 0.5em;
      }

    }

  }

  .PromptConstructor__saveButton {
    display: block;
    width: fit-content;
    margin: 1em auto;
  }
}

// config ______

.PromptConstructorConfig__varList {
  padding: 0;
  list-style: none;

  .PromptConstructorConfig__listItem__container {
    width: 30%;
    min-width: fit-content;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1em;
    

    h2 {
      font-size: 1.5em;
      margin: 0;
      color: $color-blue-secondary;
    }


    &:nth-child(2) .lastInputContainer button{
      opacity: 0;
    }
    
  }

  .lastInputContainer {
    display: flex;
    gap: 1em;
    align-items: center;
  }
} 


.PromptConstructorConfig {
  justify-content: space-between;
}

.PromptConstructorConfig__inputSection {
  width: 100%;
  margin: 1em auto;
  padding: 0 1em;
  
  .PromptConstructorConfig__inputSection__title {
    color: $color-blue-secondary;
    margin: 0;
    margin: 0em;
    margin-bottom: 0.4em;
  }

  .PromptConstructorConfig__inputSection__description {
    color: $color-primary;
    font-size: 1.2em;
  }
}

.PromptConstructorConfig__inputContainer {
  width: 100%;
  margin: 0 auto;
  // height: 100%;
  box-shadow: $krino-boxShadow;
  border: $color-krino-grey2 solid 1px;
  border-radius: 0.4em;

  textarea {
    border-radius: 0.4em;
    font-weight: 400;
    font-size: 1.2em;
    padding: 0.3em;
    resize: none;
    border: 0;
    width: 100%;
    height: 100%;
    outline: none;
    min-height: 8em;
  }
}

.PromptConstructorConfig__saveButton {
  width: fit-content;
  margin: 1em auto;

}

// nuevo 

.PromptConstructorConfig__intencionContainer {
  padding: 0 1em;

  .PromptConstructorConfig__intencionsectionTitle {
    color: $color-blue-secondary;
    margin: 0;
  }

  .PromptConstructorConfig__intencion {
    box-shadow: $krino-boxShadow;
    padding-left: 1.5em;
    background-color: rgba(216, 216, 216, 0.219);
    border-radius: 0.4em;
    position: relative;

    .PromptConstructorConfig__intencion__deleteButton {
      position: absolute;
      top: 1em;
      right: 1em;
    }

    .PromptConstructorConfig__intencion__name {
      font-size: 1.7em;
      margin: 1em 0;
      width: fit-content;
      border: 0;
      background-color: transparent;
      color: $color-primary;
      font-weight: 500;
      padding: 0.2em;
    }


    .PromptConstructorConfig__intencion__varlist {
      list-style: none;
      padding: 0 1.5em;
    
      li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        gap: 2em;
        // background-color: $color-krino-grey2;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 1.5em;
        border-radius: 0.4em;
        position: relative;

        .PromptConstructorConfig__intencion__varItem_deleteButton {
          font-size: 0.6em;
          position: absolute;
          top: 1em;
          right: 1em;
          background-color: transparent;
          color: $color-danger !important;
        }

        .PromptConstructorConfig__intencion__varContainer {
          width: 45%;

          p {
            color: $color-blue-secondary;
            font-size: 1.2em;
            font-weight: 500;
          }

          input {
            text-align: left;
            font-size: 1.2em;
          }

          &:last-child {
            width: 95%;
          }
        }
      
        &:not(:last-child) {
          margin-bottom: 2em;
        }

        &:last-child {
          box-shadow: unset;
        }
      }
    }
  }
}

.PromptConstructorConfig footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;
  width: 100%;
} 