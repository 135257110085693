.botData {
  width: 100%;
  display: flex;
  flex-direction: column;


  .header__infoContainer {

    .botData__title {
      margin: 0;
      text-align: center;
    }
    
    .botData__description {
      margin: 0.5em 0;
      text-align: center;
      font-weight: 500;
    }
  }

  .backButton {
    background-color: white;
    color: $color-blue-secondary;
    font-weight: 500;
    padding: 0.5em 1em;
    border-radius: 0.4em;
    border: 0;
  }

  .botData-header {
    padding: 0.5em 1em;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: fit-content;
  }

  .botData__mainSection {
    width: 100%;
    flex-grow: 1;
    min-height: 600px;
    position: relative;
  
    .filterButton {
      position: absolute;
      top: 1em;
      right: 1em;
      border-radius: 0.4em;
      border: 0;
      background-color: $color-primary;
      color: white;
      padding: 0.5em 0.7em;
      cursor: pointer;
      z-index: 99;
    }
  }
}

.botDataFilterModal {
  min-height: 80vh;

  .botDataFilterModal__title {
    text-align: center;
    color: $color-primary;
  }

  .botDataFilterModal__datePikerContainer {
    padding: 5em 1em;
    box-shadow: $krino-boxShadow;
    height: calc(100% - 3em);
    border-radius: 0.4em;

    p {
      color: $color-blue-secondary;
      font-weight: 500;
      font-size: 1.25em;
      margin: 1em 0;
    }

    .botDataFilterModal__datePiker {
      display: flex;
      align-content: center;
      justify-content: space-evenly;
    }
  }

  .botDataFilterModal__sendButton {
    display: block;
    margin: 2em auto;
    
  }
}