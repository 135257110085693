.notFountSection {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .notFount__container {
    text-align: center;

    .notFount__title {
      color: $color-blue-secondary;
      font-size: 10em;
      margin: 0;
      text-shadow: 1px 1px 2px black;
    }

    .notFount__description {
      text-align: center;
      color: $color-blue-secondary;
      font-size: 1.2em;
      font-weight: 500;
      margin: 1em;
      margin-bottom: 1.2em;
    }

    .notFount__link {
      display: block;
      width: fit-content;
      margin: 0 auto;
    }
  }
}