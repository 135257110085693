.modal-content {
	border-radius: 7px;
	border: none;
	box-shadow: none;
	overflow: hidden;

	.modal-header {
		align-items: center;
		padding: 20px 30px;
		background-color: $color-primary;
		color: white;
		border-bottom: none;

		.modal-title {
			display: flex;
			align-items: center;
			line-height: 13px;

			i, figure.avatar {
				margin-right: .7rem;
				font-size: $default-font-size + 3;
			}

			svg {
				width: 18px;
				height: 18px
			}

			& + button {
				display: flex;
				align-items: center;
				justify-content: center;
				text-shadow: none;
				font-size: $default-font-size - 2;
				margin: 0;
				background-color: rgba(white, .3);
				border-radius: 50%;
				padding: 0;
				width: 30px;
				height: 30px;
				color: white;
				opacity: 1;
			}
		}
	}

	.modal-body {
		padding: 30px;
	}

	.modal-footer {
		border-top: none;
		padding: 30px;
		padding-top: 0;
	}
}

body:not(.no-blur-effect) {
	&.modal-open .layout {
		-webkit-filter: blur(1.5px);
		-moz-filter: blur(1.5px);
		-o-filter: blur(1.5px);
		-ms-filter: blur(1.5px);
		filter: blur(1.5px);
	}

	.modal.fade .modal-dialog.modal-dialog-zoom {
		-webkit-transform: translate(0, 0) scale(.95);
		transform: translate(0, 0) scale(.95);
	}

	.modal.show .modal-dialog.modal-dialog-zoom {
		-webkit-transform: translate(0, 0) scale(1);
		transform: translate(0, 0) scale(1);
	}
}

.call {
	text-align: center;

	.modal-content {

		.modal-body {
			padding: 50px 0;
		}
	}

	.action-button {
		margin-top: 3rem;

		.btn {
			width: 70px;
			height: 70px;
			margin: 0 15px;

			svg {
				width: 28px;
				height: 28px
			}
		}
	}
}

.botNode--connectFaq__modal {
	display: flex;
	flex-direction: column;

	.connectFaq__modal__box {
		box-shadow: $krino-boxShadow;
    margin: 1em auto;
    width: 90%;
    background-color: $color-krino-white;
    padding: 1em;
    border-radius: 30px;

		input, select, textarea {
			border-radius: 20px;
			border: 0;
			box-shadow: $krino-boxShadow;
			outline: 1.2px solid rgba($color: $color-primary, $alpha: 0.4);
			padding: 0.5em;
		}
	}

    
	button {
		background-color: $color-primary;
		color: white;
		border-radius: 1em;
		padding: 0.3em 1em;
		border: 0;
		margin: 1em auto;
		// max-width: 5em;
	}
}


//AddLeadsModal

.addLeadsModal__main, .editLeadsModal__main {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	.addLeadsModal__main__title {
		color: $color-primary;
	}

	.addLeadsModal__main__section {
		width: 100%;
		box-shadow: $krino-boxShadow;
		margin: 2em 0;
		border-radius: 1em;
		padding: 1em;

		select {
			margin: 1.5em auto;
		}

		.addLeadsModal__main__section__title {
			color: $color-primary;		
		}

	}

	.inputConainer {
		height: 3em;
		margin: 2em 0;

		label {
			width: 3em;
			margin: 0 auto !important;
		}
	}
	.infoContainer {
		width: 100%;
		margin-top: 3em;
		margin-bottom: 2em;
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		button, a {
			margin-bottom: 1em;
		}
	}

	.errorMessage {
		color: $color-danger;
	}
}

//editLeadsModal

.editLeadsModal__main {

	.editLeadsModal__main__title {
		text-align: left;
		color: $color-primary;
		width: 100%;
		padding: 0 1em;
	}

	.edidLeads__form {
		padding: 1em;
		width: 100%;
		.edidLeads__form__flex {
			display: flex;
			align-items: center;
		}

		.edidLeads__form__inputContainer {
			margin: 2em 0;
			width: 100%;
			padding: 0.5em;
			min-height: 2.4em;
			box-sizing: border-box;

			input[type=color] {
				padding: 0.5em;
    		min-height: 2.4em;
			}

			input {
				width: 100%
			}

			& > p {
				margin: 0;
				margin-bottom: 0.5em;
				color: $color-primary;
				font-weight: 500;
				font-size: 1.4em;
			}

			.variableContainer {
				display: flex;
				justify-content: center;
				align-items: center;

				input, button {
					margin: 0 1em;
					display: block;
				}
			}

			.varList {
				list-style: none;
				padding: 0;

				li {
					align-items: center;
					margin: 1.5em;
					display: flex;

					p {
						color: black;
						margin: 0;
						margin-right: 1em;
					}

					button {
						border: 0;
						background-color: none;
						color: $color-danger;
						background-color: transparent;
					}
				}
			}
		}

		.edidLeads__form__inputContainer--vars {
			h5 {
				color: $color-blue-secondary;
			}
			p {
				margin-bottom: 1em;
			}
		}

		.edidLeads__form__buttonContainer {
			display: flex;
			align-items: center;
			justify-content: center;
			
			button {
				margin: 0 0.5em;
				width: 10em;
				text-align: center;
			}
		}
	}
}

//set Twilio 

.twilioCredentials__button {
	font-size: 0.95em !important;
	margin: 0 auto !important;
	width: 16em !important;
}

.twilioCredentials__main {
	// display: flex;
	// flex-direction: column;
	// align-items: center;

	form {
		margin: 0 auto;
		width: 100%;
	}

	input {
		outline-color: $color-primary;
		margin: 1.5em auto;
		width: 100%;
	}

	button {
		margin: 0 auto;
		display: block;
	}
}

// perfil modal
.PerfilModal__suscription {
	text-align: center;

	img {
		width: 5em;
	}

	h3 {
		margin-top: 0.5em;
		margin-bottom: 1em;
	}

	.menuButton {
		border: 0;
		background-color: transparent;
		color: $color-primary;
	}

}

// calificacion live chat
.qualificationModal {
	text-align: center;
	padding: 1em;

	.qualificationModal__emojiContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	
		.qualificationModal__title {
			font-size: 1.2em;
		}

		.qualificationModal__subTitle {
			font-size: 1.1em;
		}
		
		button {
			width: 6em;
			background-color: transparent;
			border: 0;
		
			&:hover {
				transform: scale(1.1);
			}
		
		}
	}
}


// .PerfilModal__suscription--success {}

// .PerfilModal__suscription--error

// edit colum modal
.editColumButton {
	position: absolute;
  top: 0.3em;
  left: 0.3em;
  background: transparent;
  border: 0;
	color: white;
}

//modal modificar elementos
.addFaqElementModal__buttonContainer {
	display: flex;
	margin: 0.5em 0;
	justify-content: space-evenly;
	align-items: center;

	button {
		width: 12em;
		text-align: center;
	}
}

// modal de tarjeta de carrousel
.carrouselModal {
	padding: 1em;
	text-align: center;
	
	.inputContainer {
		margin: 1em;
		
		p {
			margin: 0;
      margin-bottom: 0.3em;
      color: $color-primary;
		}
	}

	.carrouselModal__title {
		margin: 0 auto;
	}
	.carrouselModal__fileContainer {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.carrouselModal__description {
		margin: 0 auto;
	}
}

// emoji picker
.emojiPikerModal {
	padding: 0.5em;

	.emojiPikerModal__inputSection {
		display: flex;
		align-items: center;
		margin-bottom: 1em;
		// textarea {

		// } 

		button {
			margin-left: 0.5em;
		}

	}
}

// historial 

.conversationHistory {

	header {
		position: relative;

		.chatId {
			position: absolute;
			top: 0;
			right: 0;
			font-weight: 500;
			font-size: 1.2em;
			margin-top: 0.4em;
			color: white;
		}
	}

	.chat-body {
		flex: 1;
		padding: 30px;

		&:not(.no-message) {
			overflow: auto;
		}

		&.no-message {
			user-select: none;

			.no-message-container {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				height: 100%;
				text-align: center;

				svg {
					width: 50px;
					height: 50px;
					stroke: $color-primary;
					fill: $style-color;
				}

				p {
					font-size: $default-font-size + 2;
					color: black + 130
				}
			}
		}

		.messages {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.message-item {
				word-break: break-all;
				max-width: 75%;
				margin-bottom: 0.5rem;

				&:last-child {
					margin-bottom: 0;
				}

				.message-avatar {
					display: flex;
					align-items: center;
					margin-bottom: 1rem;

					.avatar {
						margin-right: 1rem;
					}

					h5 {
						font-size: 15px;
						margin-bottom: 0;
					}

					.time {
						color: #828282;
						margin-top: 2px;
						font-style: italic;
						font-size: 12px;

						.ti-double-check {
							margin-left: 5px;

							&:before, &:after {
								content: "✔";
							}

							&:after {
								margin-left: -2px;
							}
						}
					}
				}

				.media-iframe {
					margin-top: 0.5em;

					audio {
						display: block;
					}

					iframe {
						border-radius: 0.5em;
					}
				}

				
				.media-linkButton {
					margin-top: 0.7em;
					font-size: 1.2em;
					
					a {
						background-color: $color-primary;
						color: $style-white-secondary;
						border: 0;
						border-radius: 1em;
						padding: 0.3em 0.5em;
					}


				}

				.media-img {
					margin-top: 0.5em;
					background: $color-light-grey;
					border-radius: 0.5em;
					padding: 0.3em;
					max-width: 360px;

					img {
						border-radius: 0.5em;
						width: 100%;
						background: $color-light-grey;
					}
				}

				.message-content {
					background-color: $color-light-grey;
					border-radius: 7px;
					padding: 10px 20px;
					white-space: break-spaces;     /* Permite que el texto se envuelva normalmente */
					word-break: normal;      /* No fuerza la ruptura arbitraria de palabras */
					overflow-wrap: normal;   /* Evita dividir palabras para ajustarlas */
					overflow-wrap: break-word;
					hyphens: auto;

					&.message-file {
						display: flex;
						word-break: break-word;

						.file-icon {
							font-size: $default-font-size + 10;
							margin-right: 1rem;
							color: black + 130;
						}

						ul {
							margin-top: 3px;
							margin-bottom: 0;

							li.list-inline-item {
								margin-left: 0;
								margin-right: .5rem;
							}
						}
					}
				}

				&.outgoing-message {
					margin-left: auto;

					.message-avatar {
						justify-content: flex-end;
					}

					.media-img {
						margin-left: auto;
						background: $color-blue-secondary;
					}

					.message-content {
						background-color: $color-blue-secondary;
						color: white;
						white-space: break-spaces;     /* Permite que el texto se envuelva normalmente */
						word-break: normal;      /* No fuerza la ruptura arbitraria de palabras */
						overflow-wrap: normal;   /* Evita dividir palabras para ajustarlas */
						overflow-wrap: break-word;
						hyphens: auto;
					}

					.time {
						text-align: right;
					}
				}
			}

			.messages-divider {
				width: 100%;
				max-width: 100%;
				position: relative;

				&:before {
					content: attr(data-label);
					display: block;
					position: absolute;
					top: -8px;
					letter-spacing: .5px;
					font-size: 11px;
					padding: 2px 8px;
					border-radius: 3px;
					background-color: $style-color;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}
}

.conversationHistory__loading {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2em;
}

//TemplateContructorButtosModal
.TemplateContructorButtosModal {
	.buttons__title {
		text-align: center;
		color: $color-blue-secondary;
	}

	.butons__container {
		margin: 2em 0;
	
		.butons__container__title {
			color: $color-primary;
			margin: 0.7em;
			margin-bottom: 1.5em;
		}

		header {
			display: flex;

			label {
				margin: 0;
			}
		}

		form {
			display: flex;
			

			span {
				margin: 0 1em;

				p {
					margin-bottom: 0.3em;
					color: $color-primary;
				}
				
				input {
					text-align: center;
					margin: 0;
					background-color: transparent;
					border: 0;
					border-bottom: 0.05em solid $color-blue-secondary ;
					padding: 0.3em;
					
					&:focus {
						border-bottom: 0.05em solid transparent ;
						outline: $color-blue-secondary solid 0.05em;
						border-radius: 0.4em;
					}
				}
			}

			.urlVarsContainer {
				display: flex;
				flex-direction: column;
			
				.varSelect {
					border: $color-krino-grey2 solid 1px;
					background-color: transparent;
					padding: 0.5em;
					border-radius: 0.4em;
					max-width: 50%;
		
					&:hover {
						background-color: $color-krino-grey;
						box-shadow: $krino-boxShadow;
					}
				}
			}
		}

		.disableForm {
			pointer-events:none;
			opacity: 0.6;
		}

		.errorMessage {
			color: $color-danger;
			text-align: center;
			margin: 0.2em 0;
			font-weight: 500;
		}
	}

	.quickReplaySection {
		width: 100%;

		.quikReplayForm {
			padding: 1em 0;
			margin-bottom: 2em;

			.quikReplayForm__title {
				color: $color-primary;
				margin-bottom: 1em;
				// font-size: ;
			}

			.quikReplayForm__info {
				display: block;
				text-align: center;

			}

			.errorMessage {
				color: $color-danger;
				text-align: center;
				margin: 0.2em 0;
				font-weight: 500;
			}

			.varSelect {
				border: $color-krino-grey2 solid 1px;
				background-color: transparent;
				padding: 0.5em;
				border-radius: 0.4em;
				max-width: 50%;
	
				&:hover {
					background-color: $color-krino-grey;
					box-shadow: $krino-boxShadow;
				}
			}

			input {
				text-align: center;
				margin: 0 auto;
				background-color: transparent;
				border: 0;
				border-bottom: 0.05em solid $color-blue-secondary ;
				padding: 0.3em;
				width: 80%;
					display: block;

				&:focus {
					border-bottom: 0.05em solid transparent ;
					outline: $color-blue-secondary solid 0.05em;
					border-radius: 0.4em;
				}
			}

			.formButtonsContainer {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				padding: 0.5em 1em;
			}

		}

		.buttonsList {
			list-style: none;
			padding: 0;

			li {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0.7em;

				&:first-child {	
					border-top: 0.05em solid $color-blue-secondary;
				}

				p {
					margin: 0;
					font-size: 1.2em;
					color: $color-blue-secondary;
					font-weight: 500;
					margin-right: 1em;
				}

				button {
					font-size: 0.8em;
				}
			}
		}
	}


}

// modal para filtros del crm 
.crmFilterModal {
	width: 100%;

	.crmFilterModal__section {
		margin-bottom: 4em;

		.crmFilterModal__section__title {
			text-align: left;
			margin-left: 1em;
			color: $color-primary;
		}

		.errorMessage {
			color: $color-danger;
			font-size: 1.4em;
			text-align: center;
			margin: 1em 0;
		}

		.crmFilterModal__filterList {
			padding: 0;
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			// justify-content: center;

			li {
				margin: 1em;
			
				button:hover {
					background-color: rgba($color: $color-primary, $alpha: 0.5);
				}
			}
		}

	}
	.submitButton {
		display: block;
		margin: 0 auto;
	}
}


.chatConfigModal {
	.chatConfigModal__tagsSection {
		.chatConfigModal__tagsSection__title {
			text-align: center;
			color: $color-primary;
		}

		.chatConfigModal__tagsSection__description {
			text-align: justify;
			color: $color-blue-secondary;
			font-size: 1em;
			font-weight: 500;
		}

		.chatConfigModal__tagsSection__mainContainer {
			box-shadow: $krino-boxShadow;
			padding: 0.7em;
			border-radius: 0.4em;
			margin: 2em 0;

			.chatConfigModal__tagsSection__itemsContainer {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				list-style: none;
				padding: 0;
			
				.chatConfigModal__tagsSection__tagItem {
					font-size: 1.1em;
					margin: 0.7em;
					padding: 0.2em 0.5em;
					background-color: $color-blue-secondary;
					color: white;
					border-radius: 0.4em;
					box-sizing: $krino-boxShadow;
					cursor: pointer;

					p {
						margin: 0;
					}
				}

				.chatConfigModal__tagsSection__tagItem--red {
					background-color: $color-danger;
				}

				.chatConfigModal__tagsSection__tagItem--yellow {
					background-color: #e6a72a;
				}

				.chatConfigModal__tagsSection__tagItem--green {
					background-color: $color-krino-success;
				}
			}
			
			.chatConfigModal__tagsSection__createButton {
				border-radius: 0.4em;
				display: block;
				margin: 0 auto;
				margin-top: 3em;
			}
			
		}

		.chatConfigModal__tagsSection__saveButton {
			border-radius: 0.4em;
			display: block;
			margin: 0 auto;
		}
	}

	.chatConfigModal__tagsSection__actionSection {
		
		header {
			position: relative;

				.chatConfigModal__tagsSection__actionSection__title {
					color: $color-blue-secondary;
					text-align: center;
				}

				button {
					position: absolute;
					right: 0.5em;
					top: 50%;
					transform: translateY(-50%);
				}
		}


		.chatConfigModal__tagsSection__actionSection__form {
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			flex-wrap: wrap;
			margin: 4em 0;

			label {
				margin: 0 1em;

				p {
					margin: 0;
					color: $color-blue-secondary;
				}

				input, select {
					border-radius: 0.4em;
				}

			}
		}

		.chatConfigModal__tagsSection__actionSection__buttons {
			display: flex;
			align-items: center;
			justify-content: center;

			button {
				margin: 0 0.5em;
			}
		}
	}
}

.FaqSelectorModal {
	.FaqSelectorModal__builderContainer {
		height: 80vh;
		width: 100%;
		position: relative;

		.FaqSelectorModal__builderContainer__selectionMessage {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: $color-primary;
		}
	}

	.FaqSelectorModal__sectionSelector {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 0;
    position: relative;
    z-index: 99999999;

		.FaqSelectorModal__sectionSelector_selector {
			min-width: 30em;
		}
  }
}

.addBotSectionModal {
	.addBotSectionModal__form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		input {
			margin: 1em 0;
		}
	}
}

.apiLogsModal {
	width: 100%;
	overflow: auto;

	.logstable {
		width: 100%;
		

		thead {
			th {
				color: $color-blue-secondary;
				// text-align: center;
				padding: 0.5em;
				white-space: nowrap;
			}
		}

		tbody {
			tr {
				margin: 1em 0;

				td {
					padding: 1em;
					text-align: center;
					border: 0.02em solid #0000003b;
				}
				
			}

			.apiLogsModal__log--error {
				background-color: #cc3e3422;
			}

			.apiLogsModal__log--success {
				background-color: #06C16522;
			}

			.apiLogsModal__log--info {
				background-color: #1597E522;
			}

			.apiLogsModal__log--selected {
				font-size: 1.1em;
			}

		}
	}
}


// temperatura lead

// modal para cambiar una chat columna fuera del crm
.moveChatCrmModal {
	padding: 1em;
	.moveChatCrmModal__title {
		text-align: center;
		color: $color-primary;
		margin-bottom: 1.5em;
	}

	.moveChatCrmModal__list {
		list-style: none;
		padding: 0;

		.moveChatCrmModal__listItem{
			width: 90%;
			margin: 0 auto;
			box-shadow: $krino-boxShadow;
			overflow: hidden;
			border-radius: 0.4em;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			align-items: center;
			padding: 0.6em 0;
			
			&:not(:last-child) {
				margin-bottom: 2em;
			}

			&:hover {
				transform: scale(1.01);
				background-color: rgba($color: $color-primary, $alpha: 0.3);
			}

			.moveChatCrmModal__listItem__marker {
				content: "";
				height: 100%;
				width: 1.3em;
				position: absolute;
				left: 0;
			}
		}
	}
}

// modal add variables a contexto
.addContextModal {
	padding: 1em;

	.addContextModal__title {
		text-align: center;
		color: $color-primary;
		margin-bottom: 1.5em;
	}

	.addContextModal__list {
		list-style: none;
		padding: 0;
		display: flex;
		align-items: center;
		flex-direction: column;
	
		.addContextModal__listItem {
			margin-bottom: 1em;
		
			.addContextModal__listItem__container {
				width: 100%;
				padding: 1em 4em;
				box-shadow: $krino-boxShadow;
				border-radius: 0.4em;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				
				p {
					margin: 0;
				}

				.actionButton {
					position: absolute;
					right: 1em;
					top: 50%;
					transform: translateY(-50%);
					border: 0;
					background-color: transparent;
					color: $color-primary;
				}

				.actionButton--delete {
					color: $color-danger;
				}
			}

			.addContextModal__listItem--add {
				padding-top: 1em;
				// border-top: 0.05em solid $color-krino-grey2;
				label {
					p {
						color: $color-blue-secondary;
						margin: 0;
					}

					input {
						padding: 0.4em;
						border: 0.2em solid $color-primary;
						outline-color: $color-blue-secondary;
						border-radius: 0.4em;
					}
				}
			}
		}

	}
	.submit__button {
		display: block;
		margin: 0 auto;
	}
}


// modal agregar o actualizar modelo
.add__update__model__modal {
	h1 {
		color: $color-primary;
		margin-bottom: 0;
	
	}
	
	.formContainer {
		flex-direction: column;
	}

}