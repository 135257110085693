.dropdown-menu {
	border-color: white - 20;
	box-shadow: rgba(0, 0, 0, 0.45) 0 0 20px -10px;
	border-radius: 5px;

	.dropdown-item {
		font-size: $default-font-size;

		&.active {
			background: none;
			color: inherit;
		}

		&:focus, &:hover, &:active {
			background-color: $style-color;
			color: black;
		}
	}
}

.general__dropdownMenu {
	position: relative;

	.general__dropdownMenu__mainButton {
		border: 0;
		background-color: transparent;
	}

	.general__dropdownMenu__optionContainer {
		background-color: white;
		position: absolute;
		bottom: 0;
		left: 0;
		transform: translate(-95%, 95%);
		z-index: 999;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 0.3em 0;
		border-radius: 0.4em;
		min-width: 10em;
		box-shadow: $krino-boxShadow;

		.general__dropdownMenu__option {
			border: 0;
			background-color: transparent;
			text-align: center;
			width: 100%;
			padding: 0.5em 0;
			font-weight: 500;

			&:hover {
				background-color: rgba($color: $color-primary, $alpha: 0.1);
			}

			&:not(:last-child) {
				border-bottom: 1px solid $color-krino-grey2;
			}
		}

		.general__dropdownMenu__option--error {
			color: $color-danger;
		}

		.general__dropdownMenu__option--success {
			color: $color-success;
		}
	}
}