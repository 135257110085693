
.botReminders {
  height: 100%;
  // max-height: inherit;
  width: 100%;
  display: flex;
  border-top: 0.04em solid $color-krino-grey2;
  padding: 0.5em;

  .botReminders_remindersPannel {
    width: 20%;
    max-width: 20em;
    min-width: 18.5em;
    border-right: 0.04em solid $color-krino-grey2;
    height: 100%;
    overflow: auto;

    .botReminders_remindersPannel__title {
      color: $color-blue-secondary;
      text-align: center;
      font-size: 1.5em;
      margin: 0.5em 0;
    }

    .botReminders_remindersPannel__list {
      list-style: none;
      padding: 0;

      .botReminders_remindersPannel__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 0.04em solid $color-krino-grey2;
        background-color: #eeeeee;

        .botReminders_remindersPannel__item__interactive {
          flex-grow: 1;
          padding: 1em 0;
          padding-left: 0.5em;
        }

        .botReminders_remindersPannel__item__dropDownContainer {
          padding: 1em 0;
          padding-right: 0.5em;
        }

        .botReminders_remindersPannel__item__title{
          margin: 0;
        }

        .botReminders_remindersPannel__item__description {
          line-break: anywhere;
          font-weight: 500;
        } 


        &:last-child {
          border-bottom: 0.04em solid $color-krino-grey2;
        }

        &:hover {
          cursor: pointer;
          background-color: rgba($color: $color-primary, $alpha: 0.3);
        }
      }
    }

    .botReminders_remindersPannel__item--selected {
      background-color: rgba($color: $color-primary, $alpha: 0.3) !important;
    }

    .botReminders_remindersPannel__addButton {
      display: block;
      margin: 1em auto;
    }
  }
}

// --- reminder contructors ---

.botConstructorContainer {
  flex-grow: 1;
  overflow: auto;
}

.botRemindersContructor {
  .botRemindersContructor__saveButton {
    padding: 2em;
    
    button {
      display: block;
      margin: 0 auto;
      font-size: 1.2em;
    }

  }
  

}

.reminderConstructor__title {
  border-bottom: 0.04em solid $color-krino-grey2;

  h2 {
    color: $color-primary;
    margin: 0;
  }

  button {
    font-size: 0.7em;
  }

}

.reminderConstructor__title, .reminderConstructor__title--edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: $krino-boxShadow;
  padding: 0.4em 0.5em;
} 

.reminderConstructor__title--edit {

  .reminderConstructor__title--edit__inputSection {
    flex-grow: 1;
    p {
      margin-bottom: 0.2em;
      color: $color-blue-secondary;
      font-weight: 500;
    }

    input {
      width: 100%;
      border: 0;
      border-bottom: 0.05em solid $color-blue-secondary;
      color: $color-primary;
      outline-color: $color-primary;
      padding: 0.2em;
      font-size: 1.2em;
    }
  }

  .reminderConstructor__title--edit__buttonsSection {
    align-self: flex-end;
    padding-bottom: 0.25em;
    button {
      font-size: 0.8em;
      margin: 0 0.25em;

      &:disabled {
        opacity: 0.5;
        background-color: #c5c5c5;
      }
    }
  }
}

.reminderConstructor__section {
  padding: 1em 0.5em 3em 0.5em;
  border-bottom: 0.04em solid $color-krino-grey2;
  
  .reminderConstructor__sectionTitle {
    color: $color-primary;
  }

  .reminderConstructor__sectionDescription {
    // color: $color-primary;
    font-weight: 500;

    span {
      color: $color-primary;
    }
  }

  .reminderConstructor__infoContainer {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      margin-right: 1em;
    }
  }

  .reminderConstructor__input {
    width: fit-content;
    min-width: 2em;
    border: 0;
    border-bottom: 0.05em solid $color-blue-secondary;
    color: $color-primary;
    outline-color: $color-primary;
    padding: 0.2em;
    font-size: 1.2em;
    font-weight: 500;
    margin: 0.3em 0.5em;
  }

  .reminderConstructor__infoText {
    color: $color-primary;
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0.5em;
  }

  .reminderConstructor__errorText {
    color: $color-danger;
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0.5em;
  }
}

.reminderConstructor__Template {
  .reminderConstructor__Template__templateMessage {
    border-radius: 0.4em;
    background-color: $color-blue-secondary;
    color: white;
    padding: 1em;
  }

  .reminderConstructor__Template__noTemplateMessage {
    color: $color-danger;
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0.5em;
  }
}

.templateSelectorModal {
  padding: 1em;


  .templateSelectorModal__list {
    padding: 0;
    list-style: none;

    .templateSelectorModal__listItem {
      border-radius: 0.4em;
      background-color: $color-blue-secondary;
      color: white;
      padding: 1em;
      cursor: pointer;

      &:hover {
        transform: scale(1.025);
      }

      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }
}

.reminderConstructor__faqSelector {
  .reminderConstructor__faqSelector__FaqText {
    font-size: 1.1em;
    font-weight: 500;
    margin-bottom: 0.5em;
  
    span {
      color: $color-primary;
    }
  }
}

.reminderConstructor__time {
  .reminderConstructor__time__section {
    padding-left: 1em;
    margin: 2em 0;

    .reminderConstructor__time__section__title {
      color: $color-primary;
    }
  }

  .reminderConstructor__time__TimeZoneContainer {
    p {
      font-size: 1.2em;
      font-weight: 500;
      color: $color-blue-secondary;
      margin-bottom: 0.5rem;
    }
  }

  .reminderConstructor__time__TimeZoneform {
    label {
      margin: 1em 0;

      p {
        font-size: 1.1em;
      }

      select {
        width: 100%;
        border: 0;
        border-bottom: 0.05em solid $color-blue-secondary;
        color: $color-primary;
        outline-color: $color-primary;
        padding: 0.2em;
        font-size: 1.2em;
        font-weight: 500;
      }
    }

    button {
      display: block;
    }
  }

  .reminderConstructor__time__sendForm {
    font-weight: 500;

    input {
        width: fit-content;
        min-width: 2em;
        border: 0;
        border-bottom: 0.05em solid $color-blue-secondary;
        color: $color-primary;
        outline-color: $color-primary;
        padding: 0.2em;
        font-size: 1.2em;
        font-weight: 500;
        margin: 0.3em 0.5em;
    }

    .reminderConstructor__time__sendForm__errorMessage {
      display: block;
      margin: 0.5em;
      font-weight: 500;
      color: $color-danger;
    }
  }
}

.reminderConstructor__section__availabelTime {
  .reminderConstructor__section__availabelTime__statusInfo {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      margin-right: 1em;
    }
  }

  .reminderConstructor__section__availabelTime__timeContainer {
    display: flex;
    align-items: center;
    margin: 1em 0;

    p {
      font-weight: 500;
      margin: 1em;
    }

    input {
      padding: 0.5em;
      border-radius: 0.4em;
    }
  }
}

.reminderConstructor__section__weekDays {
  .reminderConstructor__section__weekDays__daysContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  
    label {
      display: flex;
      margin: 0.5em;
      cursor: pointer;
      padding: 0.5em;

      p {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        margin: 0;
        margin-left: 0.5em;
        font-size: 1em;
        font-weight: 500;
      }

      &:hover {
        color: $color-primary;
      }
    }

  }
}