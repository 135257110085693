.numberActivation {
  width: 100%;
  padding: 1em;
  overflow-x: hidden;

  header {
    text-align: center;
    h1 {
      color: $color-primary;
    }
  }

  .numberActivation__form {
    .numberActivation__stepSection {
      padding: 1em;
      transition: all 0.8s ease-in;
      transition-property: right, opacity;
      position: relative;

      .sectionTitle {
        text-align: center;
        margin: 1.5em;
        color: $color-blue-secondary;
      }


      .numberActivation__formContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    
      .numberActivation__inputContainer {
        text-align: center;
        margin: 1em 0;
        p {
          color: $color-primary;
          margin: 0.3em 0;
          font-weight: 500;
          font-size: 1.1em;
        }

        input, select {
          border: 0;
          border-bottom: 0.2em solid $color-blue-secondary;
          outline-color: $color-blue-secondary;
          padding: 0.5em;
          width: 15em;
          background-color: $color-krino-grey;
        }

        select {
          text-align: center;
        }
      } 
    }

    .numberActivation__stepSection--forAnimate {
      right: -200%;
      opacity: 0;
    }

    .numberActivation__stepSection--animated {
      right: 0%;
      opacity: 1;
    }

    .submit_button {
      margin: 1em auto;
      text-align: center;
      display: block;
    }
  }
}