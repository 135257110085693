
.crm__section {
  margin-top: 5em;
  padding: 0 1em;
  padding-bottom: 2em;
  width: 100%;

  header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 1em;

    button {
      margin-left: 1em;
    }

    div {
      select:first-child {
        margin-right: 1em;
      }
    }
  }
}

.crm_pagesAndSeachContainer {
  display: flex;
  gap: 1em;
  margin-bottom: 1em;
}

.crm_pagesContainer {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 1em;

  p {
    color: $color-primary;
    font-weight: 500;
    margin: 0;
  }
}

.crm_searchContainer form {
  display: flex;
  gap: 0.5em;

  input {
    padding: 0 1em ;
  }
}

.crm__main {
  display: flex;
  justify-content: left;
  width: 100%;
  max-width: 91vw;
  overflow-x: visible;
  border-radius: 0.3em;
}

.crm__columSection:not(:first-child) {
  margin-left: 1em; 
  position: relative;
}

.crm__columdelete {
  position: absolute;
  top: 0.3em;
  right: 0.3em;
  background: transparent;
  border: 0;
}

.crm__Loading {
  margin: 0 1em;
  width: 1.8rem;
}

.crm__columSection__title {
  user-select: none;
  text-align: center;
  background-color: $color-primary;
  color: white;
  border-radius: 0.3em;
  margin: 0;
  padding: 0.7em 0;
  box-shadow: $krino-boxShadow;
  white-space: normal;
  word-wrap: break-word;
  max-width: 100%;
  height: 3em;               // Altura fija
  display: flex;
  align-items: center;       // Centra verticalmente el contenido
  justify-content: center;   // Centra horizontalmente el contenido
  overflow: hidden;          // Oculta cualquier contenido que se desborde
}


.crm__colum {
  user-select: none;
  width: 280px;
  min-width: 280px;
  padding: 0.4em;
  background-color: whitesmoke;
  
  overflow: visible;
  overflow-y: auto;
  margin: 0 0.5em;
  height: calc(100vh - 180px);
  position: relative; // Añadido para el contenedor de padding

  &::after {
    content: '';
    display: block;
    height: 2em; // Espacio al final de la columna
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

.crm__colum > div {
  padding-bottom: 2em;
}


.crm__colum--dragOver {
  background-color: rgba($color: $color-primary, $alpha: 0.25);

}

.crm__card {
  position: relative;
  overflow: visible;
  border-radius: 0.3em;
  user-select: none;
  padding: 1.2em;
  margin: 0 0 1em 0; // Aumentado el margen inferior
  background-color: $color-krino-white;
  box-shadow: $krino-boxShadow;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    margin-bottom: 1em; // Asegura espacio después de la última card
  }
}


.crm__card--drag {
  background-color: $color-krino-grey;
}

.crm__card--highlighted {
  border: 2px solid #1597e5;
  background-color: #7ac4ef; /* Change to any color you prefer */
}

.crm__colum .crm__card.crm__card--highlighted {
  border: 2px solid #1597e5;  /* Cambia esto al estilo que quieras para los elementos destacados */
  background-color: #7ac4ef;  /* Ejemplo de un color de fondo para destacar */
}
.crm__addCardContainer {
  min-height: fit-content;
  padding-bottom: 0.4em;
  overflow: visible;
  width: 100%;
  margin: 0 auto;
  background-color: transparent;

  .crm__addCardButton {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0.1em solid $color-blue-secondary;
    border-radius: 0.3em;
    color: $color-blue-secondary;
  }

  .crm__addCardForm {
    
    input {
      border: 0;
      margin-bottom: 0.5em;
      border-radius: 0.3em;
    }

    .crm__addCardForm__buttonContainer {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
  
      button {
        font-size: 0.9em;
      }

    }

  }
}

.crm__SideBar {
  position: relative;
  padding: 1em;
  min-width: 25em;
  max-width: 70vw;
  text-align: center;
  padding-top: 2em;

  .crm__SideBar__closeButton {
    position: absolute;
    top: 0.3em;
    left: 0.3em;
    background-color: transparent;
    border: 0;
  }
  
  .crm__SideBar__title {
    font-size: 1.7em;
  }

  .crm__SideBar__description {
    font-size: 1.3em; 
  }

  .crm__SideBar__list {
    text-align: left;
    padding: 0.5em;
    li {
      list-style: none;
      &:not(:last-child) {
        margin-bottom: 0.5em;
      }
    }

  }

  .crm__SideBar__buttonContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;

    button {
      margin: 1em auto;
    }
  }

  
}

.crm__SideBar__notes {
  text-align: left;

  textarea {
    width: 100%;
    border-radius: 1em;
    resize: none;
    padding: 0.3em;
  }
}


.crm_botSelector {
  min-width: 15em;
}


.crm__card {
  overflow-wrap: break-word;
  overflow: visible;
  border-radius: 0.3em;
  user-select: none;
  padding: 1em;
  margin: 0 0 8px 0;
  min-height: 50px;
  background-color: $color-krino-white;
  box-shadow: $krino-boxShadow;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crm__card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%; // Add this to ensure content takes full width

  p {
    margin: 0.5em 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5em;
  }
}

.crm__card .contact-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  position: relative;
  margin-left: 10px;

  &::before,
  &::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    pointer-events: none;
  }

  &::before {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    z-index: 1000;
  }

  &::after {
    content: '';
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
    bottom: 110%;
  }

  &:hover::before,
  &:hover::after {
    opacity: 1;
    visibility: visible;
  }
}

.crm__executive-select {
  max-width: 100%; // Add max-width
  width: auto; // Make width automatic
  min-width: 150px; // Set a minimum width
  height: 2rem;
  padding: 0 0.5em;
  font-size: 0.9em;
  margin-right: 0.5em;
  border: 1px solid #ccc;
  border-radius: 0.3em;
  background-color: #fff;
  
  // Add ellipsis for long options
  & option {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Add responsive button layout
.crm__executive-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-top: 0.5em;
}



.krino__button--small {
  font-size: 0.8em;
  padding: 0.3em 0.6em;
  margin: 0 0.2em;
}
.crm__executive-filter {
  display: inline-flex;
  align-items: center; // Alinea verticalmente
  gap: 0.5rem;
  padding: 0.4rem 0.8rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    border-color: #1597e5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  label {
    font-size: 0.85rem;
    color: #666;
    margin: 0;
    line-height: 1.2;
    display: flex;
    align-items: center; // Asegura que el texto se alinee con el select
  }

  select {
    border: none;
    outline: none;
    font-size: 0.85rem;
    color: #333;
    background: transparent;
    padding: 0.3rem 1.2rem 0.3rem 0.5rem;
    appearance: none;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%226%22%20viewBox%3D%220%200%2010%206%22%20fill%3D%22none%22%20xmlns%3D%22http://www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M0.5%200.5L5%205L9.5%200.5%22%20stroke%3D%22%23666%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 0.7rem auto;
    line-height: 1.2;
  }

  select:focus {
    outline: none;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
