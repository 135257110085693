.newBot__main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h3 {
    margin-top: -4em;
    margin-bottom: 1em;
    text-align: center;
    color: $color-blue-secondary;
  }
}

.newBot__main__section {
  background-color: #F3F3F3;
  box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
  border-radius: 30px;
  padding: 2em;
  width: 50em;
  text-align: center;

  .krino__box {
    margin-bottom: 2em;
  }

  .newBot__main__colorSelection {
    display: flex;
    justify-content: center;
    margin-bottom: 2em;

    input {
      margin: 1em;
      width: 10em;
      color: white;
    }
  }

  h3 {
    font-size: 1.25em;
    margin: 0;
    color: $color-primary;
  }
  
  p {
    font-size: 1em;
    margin: 1em 0;
  }

  input {
    width: 80%;
    height: 3em;
    padding: 1em;
    border-radius: 30px;
    border: 0;
    box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
    margin-bottom: 2em;

    &:focus {
      outline-color: $color-primary;
    }
    
    &[name="color"] {
      display: block;
      height: 4em;
      // width: 5em;
      // margin: 1em auto;
    }
  }
  
  select {
    display: block;
    margin: 1em auto;
    width: 80%;
    height: 3em;
    border-radius: 30px;
    border: 0;
    padding: 0 2em;
    box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
  }

  div {
    display: flex;
    justify-content: center;

    button {
      width: 7em;
      margin: 0.5em;
      border: 0;
      background-color: $color-primary;
      color: white;
      border-radius: 2em;
      padding: 0.5em 1em;

    }

    .krino__button--error {
      background-color: $color-danger;
    }


  }
}
.campaigns__StepMarker__container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 120%;
  display: flex;
  justify-content: center;
  

  .campaigns__StepMarker {
    display: block;
    background-color: #C4C4C4;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin: 0 5em;
  }
  
  .campaigns__StepMarker--active {
    background-color: $color-primary;
  }
}
