.navigation {
	background: $color-primary;
	color: $color-krino-white;
	border-right: 1px solid darken(white, 10%);
	position: relative;
	z-index: 20;
	position: sticky;
	top: 0;

	.nav-group {
		
		width: 100px;
		transition: width .3s ease-in-out;
	}

	.nav-group--big {
		width: 172px;
	}

	ul {
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0;
		margin: 0;

		li {
			list-style: none;

			&:not(.logo) {
				margin: 5px 0;

				a {
					border-radius: 7px;
				}
			}

			> a {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 15px 0;
				color: black + 130;
				position: relative;
				transition: all .3s;

				svg {
					width: 18px;
					min-width: 18px;
					height: 18px;
				}

				.badge {
					content: '';
					display: block;
					width: 8px;
					height: 8px;
					padding: 0;
					border-radius: 50%;
					position: absolute;
					top: 10px;
					right: 10px;
				}

				&.active {
					background-color: $style-color;
					color: $color-primary;
				}

				&:hover, &:focus {
					color: black !important;
				}

				i {
					font-size: $default-font-size + 5;
				}
			}

		
			&.logoContainer { 
				display: flex;
				align-items: center;
				flex-direction: column;

				a {
					height: 80px;

					img {
						width: 82px;

						path {
							fill: $color-primary;
						}
					}
				}

				button {
					display: flex;
					align-items: center;
					color: white;
					background-color: transparent;
					border: 0;
				}
			}

			// &.logoContainer--big {
			// 	flex-direction: row;
			// }

			&.scissors {
				margin-top: auto;
			}
		}
	}
}

// .navigation__menusContainer {
	// overflow-y: auto;
	// overflow-x: hidden;
// }

.navigationItem {
	a {
		color: white !important;
	}
}

.navigationItem--dropdown {
	padding: 15px 0;

	.dropdownButton {
		
		padding: 0;
		background-color: transparent;
		border: 0;
		color: white;
		margin: 0 auto;
		display: flex;
		align-items: center;

		&:hover {
			color: black;
		}

		svg {
			width: 18px;
			height: 18px;
		}

		
	}

	span {
		color: $color-primary;
	}

	.icon_ChevronDown {
		position: absolute;
    top: 0;
    right: 15%;
    transform: translateY(-10%);
		color: white;
	}

	.NavigationItem__dropDownMenu {
		display: flex;
		flex-direction: column;
		background-color: white;
		border-radius: 1em;
		color: $color-primary;
		padding: 0.5em;
		height: fit-content;
	}

}



.navigationItem--big {
	padding-left: 1.3em;

	.dropdownButton {
		margin: unset;
	}

	a {
		justify-content: left !important;
	}

	svg {
		margin-right: 1em;
	}
}

.chatNavigation {
	ul {
		display: flex;
    list-style: none;
    padding: 0;
    width: 100%;
    justify-content: space-evenly;
    margin: 0;
	}

	li {
		a {
			width: fit-content;
			display: block !important;
			padding: 1em;
			border-radius: 1.3em;
		}

		.active {
			background-color: $color-primary;
			color: white;
		}
	}
}

.chatNavigation-item {
	position: relative;
	
	.chatNavigation-item__notification {
		position: absolute;
		top: 0;
		right: 0;
		content: '';
		width: 0.7em;
		height: 0.7em;
		background-color: $color-danger;
		border-radius: 50%;
		animation: notification 2.5s ease-in-out infinite;
	}
}

@keyframes notification {
		0% {
			background-color: $color-danger;
		}

		50% {
			background-color: white;
		}

		100% {
			background-color: $color-danger;
		}
}

@media screen and (max-height: 700px) {
	.navigation ul {
		max-height: 100vh;
		overflow: auto;
	}
}