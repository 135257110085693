
.tutorials__EmpyMessageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.tutorials__EmpyMessage {
  color: $color-primary;
  margin: 0;  
}



.tutorialMain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .tutorial__header {
    background-color: $color-primary;
    padding: 1em 5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    height: 5em;
    
    h1 {
      margin: 0;
    }
  }


  .tutorial__mainSection {
    flex-grow: 1;
    width: 100%;
    height: calc(100vh - 6em);
    display: flex;

    .tutorial__categories {
      height: 100%;
      width: 20%;
      border-right: 0.05em solid $color-primary;
      box-shadow: $krino-boxShadow;

      .tutorial__categories__header {
        text-align: center;
        padding: 1em;
        color: $color-blue-secondary;
        // box-shadow: $krino-boxShadow;
        // border-bottom: 0.05em solid $color-primary;

        h2 {
          margin: 0;
        }
      }

      .tutorial__categories__list {
        list-style: none;
        padding: 1em 0.5em;
        text-align: center;
        width: 100%;

        li {
          font-size: 1.3em;
          font-weight: 500;
          margin-bottom: 1em;
          cursor: pointer;
        }

        .selected {
          color: $color-primary;
        }
      }
    }

    .tutorial__content {
      width: 100%;
      padding: 1em;
      overflow-y: auto;

      .Tutorials_list {
        list-style: none;
        padding: 0;
      
        .tutorialItem {
          width: 85%;
          margin: 5em auto;

          h4 {
            color: $color-primary;
            margin-bottom: 1em;
          }

          .tutorialVideo__container {
            width: 100%;
            aspect-ratio: 2 / 1;
          }
        }
      }
    }

  }
}