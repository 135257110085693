.AdminMenu__main {
  width: 100%;
  padding: 1em 0;

  .AdminMenu__main_title {
    color: $color-blue-secondary;
    text-align: center;
  }

  .AdminMenu__section {
    margin: 1em auto;
    width: 95%;
    box-shadow: $krino-boxShadow;
    border-radius: 0.4em;
    background-color: $color-krino-grey;
    padding: 1em;

    .AdminMenu__section__title {
      margin: 0.5em 0;
      color: $color-primary;

    }

    .AdminMenu__section__container {
      margin: 1em 0.5em;
      border-top: 0.05em solid $color-krino-grey2;
      padding: 2em;
    
      h5 {
        color: $color-primary;
        font-size: 1.3em;
        margin-bottom: 1em;
      }
    }
  }

  .adminMenu__form, .AdminMenu__section__container__search {

    label {
      display: block;
    }


    .selector {
      border-radius: 0.4em;
      padding: 0.5em;
      width: 50%;
      color: black;

      &:focus {
        outline: $color-primary solid 2px;
      }
    }


    input, select {
      border: $color-krino-grey2 solid 0.04em;
      border-radius: 0.4em;
      padding: 0.5em;
      width: 50%;

      &:focus {
        outline: $color-primary solid 2px;
      }
    }

    input {
      padding: 0.4em;
    }

    .adminMenu__form__container {
      p {
        color: $color-blue-secondary;
        margin: 0.4em 0;
      }
    }

    .adminMenu__buttonContainer {
      margin: 1em 0;
    }
  }
}

.CampaingMassiveSender {
  margin: 2em auto;
  width: 80em;
  max-width: 100%;
  min-width: 300px;
  border-radius: 0.4em;
  box-shadow: $krino-boxShadow;
  background-color: $color-dark;
  color: $color-light;
  padding: 1em 3em;
  

  .krino__button {
    margin: 1em 0;
  } 
}