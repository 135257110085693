
.login {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: Url("../img/login/backgroundImg.png");
  overflow-x: hidden;
  overflow-y: auto;

  .login__header {
    padding: 1em 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 7.5em;
    }

    .login__header__linksContainer {
      font-size: 1.3em;

      a:first-child {
        margin-right: 1em;
      }
    }
  }

  .login__formContainer {
    padding: 2em 0;
    width: 100%;
    min-height: 92%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login__form {
    font-size: 0.8em;
    display: block;
    margin: 0 auto;
    max-width: 35em;
    min-width: 320px;
    max-height: 100%;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);


    .logginGoogleButton {
      width: fit-content;
      margin: 1em auto;
    }

    .login__form__title {
      text-align: center;
      font-size: 2em;
      margin-bottom: 2em;
      color: $color-primary;
    }
 
    .login__form__inputContainer {
      margin-bottom: 4em;
    }

    .login__form__inputTitle {
      margin: 0;
      font-size: 1.5em;
      font-weight: 400;
      color: #757575;
      margin-bottom: 0.5em;
    }

    .login__form_numberContainer {
      display: flex;

      & input:first-child {
        width: 4em;
        margin-right: 1em;
      }
    }

    .login__form__input {
      font-size: 1.2em;
      border: 0;
      background-color: transparent;
      outline: none;
      border-bottom: 0.2em solid  rgba($color: $color-blue-secondary, $alpha: 0.8); 
      border-radius: 0.4em;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 0.4em;
      width: 100%;
      text-align: center;
    }

    .login__form__input::-webkit-outer-spin-button,
    .login__form__input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .login__form__input[type=number] {
      -moz-appearance: textfield;
    }

    .login__form__button {
      margin: 0 auto;
      margin-bottom: 2em;
      font-size: 1.6em;
      width: 72%;
      display: block;
    }

    .loginLink__container {
      text-align: center;
      
      .loginLink {
        color: $color-blue-secondary;
        font-size: 1.1em;
      }
      
      .loginLink--signUp {
        font-size: 1.5em;
        
        span {
          color: $color-primary;
        }
      }
      
      // Destaca el enlace de "Recuperar contraseña"
      .loginLink--resetPassword {
        color: #d9534f; /* Tono rojo suave para mayor visibilidad */
        font-weight: bold;
        text-decoration: none;
        border-bottom: 2px solid transparent;
        transition: border-bottom 0.3s;
      }
      
      .loginLink--resetPassword:hover {
        border-bottom: 2px solid #d9534f;
      }
    }
    
  }
  
  small {
    margin-top: 3em;
    color: $color-blue-secondary;
    font-size: 1em;
    font-weight: 500;
    display: block;
    text-align: center;
  }

}

//  actuvar cuenta

.verifyAccount {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .verifyAccount__mainContainer {
    flex-grow: 1;
    padding-bottom: 3em;
 
    .verifyAccount__StatusImg {
      width: 15em;
      margin: 5em auto;
      display: block;
    }

    .verifyAccount__title {
      color: $color-primary;
      text-align: center;
      font-size: 3.5em;
    }

    .verifyAccount__description {
      color: $color-blue-secondary;
      text-align: center;
      font-weight: 500;
      font-size: 1.4em;
    }

    .verifyAccount__button {
      font-size: 1.5em !important;
      margin: 0 auto;
      margin-bottom: 2em;
      padding-left: 3em;
      padding-right: 3em;
      font-size: 1.6em;
      text-align: center;
      margin: 0 auto;
      display: block;
      width: fit-content;
    }
  }

  .verifyAccount__mainContainer--error {
    .verifyAccount__title {
      color: $color-danger;
    }

    .verifyAccount__description {
      color: $color-google;

      a {
        color: $color-primary;
        font-weight: 500;
      }
    }
  }
}