.dataTable__main {
  width: 80vw;
  margin: 1em auto;
  // max-height: 80%;
  // overflow: auto;
  padding: 1em;
  height: fit-content;

  select {
    margin-bottom: 1em;
  }

  header {
    margin-bottom: 1em;
  }
}

.dataTable__tableSection {
  // overflow: auto;
  max-width: fit-content;
  margin: 0 auto;
  
  table {
    overflow: scroll;
  }
}



// dataTableSelection

.dataTableSelection__main {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .dataTableSelection__Maintitle {
    color: $color-primary;
    margin-top: -1em;
    margin-bottom: 1em;
  }

  .dataTableSelection__infoContainer {
    padding: 2em;
    text-align: center;
  }

  .dataTableSelection__title {
    font-size: 2em;
    margin-bottom: 1em;
  }

  .dataTableSelection__select {
    margin: 0 auto;
  }

  .dataTableSelection__errorMessage {
    color: $color-danger;
    font-size: 1.2em;
    margin: 1em 0;
  }

  .dataTableSelection__spinnerContainer {
    margin: 1em 0;
  }

  .dataTableSelection__okMessage {
    margin: 1em 0;
    
    h4 {
      font-size: 1.2em;
      margin-bottom: 1em;

    }

  }

}

// table

.table {
  tbody tr:nth-child(2n) {
    background-color:  rgba($color: $color-krino-grey2, $alpha: 0.2);
  }

  tbody tr:hover {
    background-color:  rgba($color: $color-primary, $alpha: 0.05);
  }
}

.dt-button {
  background-color: $color-primary;
  color: white;
  border: 0;
  border-radius: 1em;
  padding: 0.5em 1em;
  margin-top: 1em
}