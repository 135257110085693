.edgeBody {
  background-color: transparent;
  width: 100%;
  height: 100%;

  button {
    padding: 0;
    width: 100%;
    height: 100%;
  }
}

.bot-builder__selection {
  position: absolute;
  z-index: 6;

  .faqMenu {
    padding: 1em;
    padding-top: 0;
    max-width: 22em;
    background-color: $color-blue-secondary;
    color: white;
    height: 100vh;
    border-top-right-radius: 1.2em;
    border-bottom-right-radius: 1em;
    animation: slice-in ease-out 0.3s;

    .faqMen__menuSelection {
      margin-bottom: 1em;
      width: 100%;
      display: flex;
      position: relative;

      .selected {
        color: white;
        background-color: $color-primary;
      }

      button {
        background-color: red;
        border: 0;
        width: 100%;
        display: block;
        margin-left: -1em;
        padding: 0.5em 0;
        box-shadow: $krino-boxShadow;
        background-color: $color-krino-white;
        font-weight: 500;

        &:last-child {
          border-top-right-radius: 1em;
          margin-left: 0;
          margin-right:-1em;
        }
      }
    }

    .cardsContainer {
      overflow: auto;
      max-height: 85%;
      padding: 0 0.5em;


      .cardsContainer__searchContainer {
        padding: 0.5em 0 1em 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        input {
          border-radius: 0.4em;
          color: $color-primary;
          outline-color: $color-primary;
          width: 100%;
          font-weight: 500;
        }
      }
    }

    .cancelButton {
      border: none;
      background-color: transparent;
      margin-left: auto;
      color: white;
      display: block;
      margin-bottom: 2em;
    }

    .faqCard {
      background-color: $color-blue-secondary;
      border: 0.2em solid white;
      border-radius: 1em;
      padding: 0.4em;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 1em;
      }
    } 
  }

  .button--add {
    height: fit-content;
    background-color: $color-blue-secondary;
    color: white;
    border: 0;
    cursor: pointer;
    margin: 1em;
    border-radius: 1em;
    padding: 0.6em 1.5em;
  }

  .selectPosition {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 9999;
    background: rgba($color: #000000, $alpha: 0.5);
    top: 0;
    right: 0;
  
    h2 {
      text-align: center;
      margin: 1em auto;
    }
  }
}

@keyframes slice-in {
  0% {
    position: relative;
    left: -150%;
  }

  100% {
    position: relative;
    left: 0;
  }
}

.BotBuilderSelection {
  width: 87vw;
  // min-width: 50em;
  margin: 1em auto;

  .BotBuilderSelection__tableContainer {
    width: 100%;
    max-height: 80vh;
    overflow: auto;  
  }

  .BotBuilderSelection__vewMoreButton {
    border-top: 1px solid #cadeeb;
    background-color: transparent !important;

    td {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    }

  }

  .buttonImg {
    width: 25px;
    height: 25px;
  }
}

.botBuilder-floatingButton {
  position: absolute;
  z-index: 5;
  border-radius: 1em;
  background-color: $color-blue-secondary;
  color: white !important;
  padding: 0.7em;
  border: 0;
}

.botBuilder-BackButton {
  top: 4.5em;
  left: 1em;
  padding: 0.5em;
}

.botBuilder-BackButton--viewOnly {
  top: 1.2em;
}

.botBuilder-launchButton {
  top: 1.2em;
  right: 1em;
}

.botBuilder-tutorialButton {
  right: 1em;
  top: 4.5em;
}

.botBuilder-testButton {
  right: 1em;
  bottom: 1.2em;
}

.botBuilder-searchbutton {
  left: 3.2em;
  bottom: 0.6em;
  padding: 0.4em;
}

.botBuilder-metricsButton {
  right: 1em;
  bottom: 4.5em;
}

.botBuilder-metricsViewer {
  position: absolute;
  z-index: 5;
  border-radius: 1em;
  background-color: white;
  padding: 0.7em;
  border: 0;
  right: 1em;
  top: 4.5em;
  font-size: 1em;
  box-shadow: $krino-boxShadow;
  border-radius: 0.4em;

  .botBuilder-metricsViewer__title {
    text-align: center;
    color: $color-primary;
  }

  .botBuilder-metricsViewer__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .botBuilder-metricsViewer__element {
      margin: 1em 0;

      .botBuilder-metricsViewer__element__title {
        font-weight: 800;
        font-size: 1.2em;
        text-align: center;
        color: $color-primary;
        white-space: pre-wrap;
        overflow-wrap: anywhere;
      }

      .botBuilder-metricsViewer__element__description {
        font-weight: 800;
        font-size: 1.4em;
        color: $color-blue-secondary;
        text-align: center;
      }
    }
  }
}

.botBuilder-filterMetricsButton {
  left: 1em;
  top: 4.5em;
  padding: 0.5em;
}

.botBuilder-filterMetricsButton--open {
  position: absolute;
  z-index: 5;
  border-radius: 1em;
  background-color: white;
  box-shadow: $krino-boxShadow;
  padding: 2em;
  border: 0;
  left: 1em;
  top: 4.5em;

  .filterMetricsButton__closeButton {
    position: absolute;
    top: 0.6em;
    left: 0.6em;
    font-size: 0.4em;
  }

  .filterMetricsButton__title {
    text-align: center;
    color: $color-primary;
  }

  .filterMetricsButton__datePikerContainer {
    padding: 2em 1em;
    box-shadow: $krino-boxShadow;
    height: calc(100% - 3em);
    border-radius: 0.4em;

    p {
      color: $color-blue-secondary;
      font-weight: 500;
      font-size: 1.25em;
      margin: 1em 0;
    }

    .filterMetricsButton__datePiker {
      display: flex;
      align-content: center;
      justify-content: space-evenly;
    }
  }

  .filterMetricsButton__sendButton__container {
    display: flex;
    align-items: center;
    justify-content: center;

    .filterMetricsButton__sendButton {
      margin: 2em 0.5em; 
    }
  }
}

.connectionLine {
  stroke: $color-blue-secondary !important;
  
  &:hover {
    stroke: $color-primary !important
  }
}

.connectionLine-up {
  stroke: #b1b1b7 !important;
}

.connectionLine--border {
  stroke: transparent !important;

  &:hover ~ .connectionLine {
    stroke: $color-primary !important;
  }
}

.connectionLine--error {
  stroke: $color-danger !important;
}

.builderSearchBar {
  position: absolute;
  top: 2em;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: $krino-boxShadow;
  border-radius: 0.3em;
  z-index: 999;
  background-color: white;

  form {
    display: flex;
    align-items: center;
    padding: 1em;

    .builderSearchBar__close {
      border: 0;
      background-color: transparent;
      color: $color-danger;
    }

    .builderSearchBar__input {
      margin: 0 0.5em;
      padding: 0.3em;
      text-align: center;

    }
    
    .builderSearchBar__search {
      border-radius: 0;
    }
  }

  .builderSearchBar__resultSection {
    display: flex;
    margin: 0.4em;
    justify-content: center;
    align-items: center;

    button {
      margin-left: 0.5em;
      background-color: transparent;
      border: 0;
    }
  }

}

.mapComponent {
  width: 100%;
  height: 40em;
  & > div {
    height: 40em;
  }
}

.builderLoadingFaqsMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.bot-builder__selection__modalfrom {
  position: relative;
  padding: 2em 0;

  .bot-builder__selection__modalfrom__info {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    color: $color-blue-secondary;
    font-weight: 500;
  }

  p {
    color: $color-primary;
    margin: 0.5em 0; 
    font-weight: 500;
  }

  .bot-builder__selection__modalfrom__newSectionInfo {
    margin: 0 auto;
    .bot-builder__selection__modalfrom__newSectionInfo__warn {
      color: $color-blue-secondary;
      text-align: center;
    }

    .bot-builder__selection__modalfrom__newSectionInfo__error {
      color: $color-danger;
      text-align: center;
    }

    button {
      margin: 0 auto;
      display: block;
    }
  }

}

.BotBuilder__loadingMessage {
  position: absolute;
  top: 2em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .BotBuilder__loadingMessage__text {
    color: $color-primary;
    margin: 0;
    margin-right: 0.5em;
    font-size: 1.6em;
  }
}

.BotBuilder__sectionform {
  position: absolute;
  top: 1.4em;
  right: 2.5em;
  transform: translateX(-50%);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .BotBuilder__sectionform__selector {
    min-width: 15em;
  }

}

.BotBuilder__connectToSection {
  position: absolute;
  bottom: 1.2em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

}