.onboarding {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: Url("../img/login/backgroundImg.png");
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 2em;

  .onboarding__header {
    padding: 4em 0 2em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      width: 23em;
    }
  }

  .onboarding__main {
    padding: 1em;
    flex-grow: 1;
  }

  .buttonsSection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2em 0;

    .krino__button {
      margin: 0 0.5em;
    }
  }

  .stepMarker__conteiner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;

    .stepMarker {
      border-radius: 50%;
      width: 0.7em;
      height: 0.7em;
      // box-shadow: $krino-boxShadow;
      background-color: $color-krino-grey2;
      margin: 0 1em;
    }

    .stepMarker--selected {
      background-color: $color-blue-secondary;
    }
  }
}


// presentacion
.onboardinnPresentation {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  padding: 5em 0;

  .onboardinnPresentation__title {
    color: $color-blue-secondary;
    font-size: 3em;
  }

  .onboardinnPresentation__subtitle {
    color: $color-primary;
    font-size: 1.5em;
  }

  .onboardinnPresentation__text {
    font-weight: 500;
    font-size: 1.5em;
  }
}

.AvatarSelection {
  height: 100%;

  .AvatarSelection__title {
    text-align: center;
    color: $color-primary;
  }


  .typeSelector {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin: 2em;
      border: 0;
      box-shadow: $krino-boxShadow;
      background-color: white;
      border-radius: 0.4em;
      padding: 1em 3em;
      font-size: 1em;
      font-weight: 500;
    
      &:hover {
        background-color: $color-krino-grey;
        transform: scale(1.01);
      }
    }

    .selected {
      color: $color-primary;
    }
  }

  .avatarsContainer {
    .avatarList {
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      gap: 1em;
      justify-content: center;
      align-items: center;
    
      .avatarItems {
        margin: 1em;
      
        &:hover {
          background-color: rgba($color: $color-primary, $alpha: 0.1);
          border-radius: 0.4em;
        }
      }

      .avatarItems--selected {
        background-color: rgba($color: $color-primary, $alpha: 0.3);
        border-radius: 0.4em;
      }
    }    
  }
}

.profileDataForm, .BusinessInfo {
  height: 100%;
  .AvatarSelection__title {
    text-align: center;
    color: $color-primary;
  }

  .profileDataForm__formContainer, .BusinessInfo__formContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profileDataForm__form, .BusinessInfo__form {
    label {
      margin: 2em;

      p {
        font-weight: 500;
        color: $color-blue-secondary;
        margin: 0;
      }

      input, select {
        margin: 0;
        border: 0;
        box-shadow: $krino-boxShadow;
        background-color: white;
        border-radius: 0.4em;
        padding: 1em 3em;
        font-size: 1em;
      }

      input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }
    }
  }

  .BusinessInfo__form {
    label {
      display: block;
      margin: 2em  auto;
    
      select {
        padding: 1em 0;
        padding-left: 1em;
      }

      input, select {
        width: 100%;
        text-align: center
      };

      p {
        text-align: center;
      }

    }
  }
}

.liveChatConfig {
    height: 100%;

    .liveChatConfig__title {
      text-align: center;
      color: $color-primary;
    }
  
    .mainContainer {
      height: 100%;
    
      .emailContainer {
        margin: 2em 0;
        .emailContainer__description {
          color: $color-blue-secondary;
          font-weight: 500;
          text-align: center;
        }
      
        .emailContainer__emailInfo {
          margin: 1em 0;
          display: flex;
          align-items: center;
          justify-content: center;
        
          .emailContainer__emailInfo__title {
            margin: 0;
            color: $color-primary;
          }

          button {
            font-size: 0.8em;
            border: 0;
            border-radius: 0.4em;
            color: white;
            background-color: $color-blue-secondary;
            padding: 1em 1em;
            box-shadow: $krino-boxShadow;
            margin-left: 1em;
          
            &:last-child {
              background-color: $color-danger;
            }

            &:hover {
              transform: scale(1.02);
            }
          }
        }

        .emailContainer__from {
          margin: 1em 0;
          display: flex;
          align-items: center;
          justify-content: center;

            input {
              margin: 0;
              border: 0;
              box-shadow: $krino-boxShadow;
              background-color: white;
              border-radius: 0.4em;
              padding: 1em 3em;
              font-size: 1em;
            }

            button {
              border: 0;
              border-radius: 0.4em;
              color: white;
              background-color: $color-blue-secondary;
              padding: 1em 1em;
              box-shadow: $krino-boxShadow;
              margin-left: 1em;
            
              &:hover {
                transform: scale(1.02);
              }
            }
            
            button:disabled {
              background-color: $color-krino-grey2;
              color: black;

              &:hover {
                transform: none;
              }
            }
        }
      }

      .scheduleContainer {
        max-width: 70em;
        margin: 5em auto;

        .scheduleContainer__description {
          color: $color-blue-secondary;
          font-weight: 500;
          text-align: center;
        }
      }
    }

}

.invitePartner {
  height: 100%;

    .invitePartner__title {
      text-align: center;
      color: $color-primary;
    }

    .invitePartner__description {
      text-align: center;
      color: $color-blue-secondary;
      font-weight: 500;
    }

    .invitePartner__mainContainer {
      .invitePartner__formContainer {
        .emailContainer__from {
          margin: 1em 0;
          display: flex;
          align-items: center;
          justify-content: center;

            input {
              margin: 0;
              border: 0;
              box-shadow: $krino-boxShadow;
              background-color: white;
              border-radius: 0.4em;
              padding: 1em 3em;
              font-size: 1em;
            }

            button {
              border: 0;
              border-radius: 0.4em;
              color: white;
              background-color: $color-blue-secondary;
              padding: 1em 1em;
              box-shadow: $krino-boxShadow;
              margin-left: 1em;
            
              &:hover {
                transform: scale(1.02);
              }
            }
        }
      }

      .invitePartner__emailListContainer {
        max-width: 80%;
        width: 80em;
        margin: 0 auto;

        .invitePartner__emailList {
          list-style: none;
          padding: 0;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          .invitePartner__emailItem {
            background-color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: $krino-boxShadow;
            width: 25em;
            margin: 2em 2em 0 2em;
            margin-top: 2em;
            border-radius: 0.4em;
            padding: 1em 2em;

            p {
              margin: 0;
            }

            button {
              color: $color-danger;
              background-color: transparent;
              border: 0;
            }
          }
        }
      }
    }
}