@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "Vars";
@import "Typography";
@import "Button";
@import "Modal";
@import "Tab";
@import "Color";
@import "Avatar";
@import "DropdownMenu";
@import "Membership";
@import "Navigation";
@import "Dark";
@import "Other";
@import "Rtl";
@import "chatLoading";
@import "botNode";
@import "DashBoard";
@import "bot-builder";
@import "dataTable";
@import 'campaign';
@import "newBot";
@import "templates";
@import "krino";
@import "wizard";
@import "launchBot";
@import "login";
@import "toc";
@import "conversations";
@import "testYourtBot";
@import "payment";
@import "billing";
@import "crm";
@import "home";
@import "liveChat";
@import "faqSelectionSection";
@import "profile";
@import "NumberActivate";
@import "poweredByKrino";
@import "templatesContructor";
@import "adminMenu";
@import "generalComponents";
@import "notFount";
@import "errorPage";
@import "onboarding";
@import "tutorials";
@import "botData";
@import "viewOnly";
@import "ECR";
@import "components";
@import "botsettings";
@import "temperatureLeadModal";
@import "temperatureLead";
@import "gpt";
@import "nuxiba";

.layout {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	display: flex;

	.content {
		overflow: auto !important;
		display: flex;
		flex: 1;

		.sidebar-group {

			display: flex;
			flex-direction: column;

			.sidebar {
				&:not(.active) {
					display: none;
				}

				.sidebar-title {
					font-size: 22px;
				}

				background: white;
				border-radius: 5px;
				overflow: hidden;
				width: 350px;
				display: flex;
				flex-direction: column;
				flex: 1;

				& > header {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 30px;
					height: 80px;
					font-weight: 600;
					border-radius: 0;

					ul {
						margin-bottom: 0;

						li {
							margin-bottom: 0;
						}
					}
				}

				& > form {
					padding: 17px 30px;
					margin-bottom: 1rem;

					.form-control {
						border-radius: 5px;
						height: auto;
						border: 1px solid $style-color;
						padding: 10px 15px;
					}
				}

				& .sidebar-body {
					flex: 1;
					overflow: auto;
				}

				.list-group-item {
					padding: 17px 30px;
					display: flex;
					align-items: center;
					cursor: pointer;
					position: relative;

					&:hover {
						background-color: $color-light-grey;
					}

					&:not(:last-child) {
						border-bottom: 1px solid $style-color;
					}

					&.open-chat {
						background-color: $color-light-blue;
						transition: background-color linear 0.2s;

						.users-list-body {
							p {
								font-weight: 600;
								color: black + 100
							}
						}
					}



					figure {
						margin-right: 1rem;
					}

					.users-list-body {
						flex: 1;
						// position: relative;
						min-width: 0px;
						display: flex;

						& > div:first-child {
							min-width: 0;
							display: flex;
							flex-direction: column;
							flex: 1;
						}

						h5 {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							font-size: $default-font-size + 2;
							font-weight: 500;
							// margin-bottom: .2rem;
							margin: 0;
						}

						p {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							margin-bottom: 0;
							color: black + 150
						}

						.origin {
							position: absolute;
							right: 0.5em;
							bottom: 0;
						}

						.users-list-action {
							position: absolute;
							top: 0.5em;
							right: 0.5em;

							[data-toggle="dropdown"] {
								i {
									font-size: $default-font-size + 4;
								}
							}

							.new-message-count {
								width: 23px;
								display: flex;
								align-items: center;
								justify-content: center;
								line-height: 0;
								font-size: $default-font-size - 1;
								height: 23px;
								background-color: $color-primary;
								color: white;
								border-radius: 50%;
								margin-left: auto;
							}

							.action-toggle {
								display: none;
								text-align: right;
								position: absolute;
								right: 0;

								.dropdown {
									& > span {
										color: $color-primary;
									}
								}
							}
						}

						.users-list-body-main {
							// margin: 1em 0;
							margin-top: 1em;

							.tagsContainer {
								display: flex;
								align-items: center;
								justify-content: space-evenly;
								width: 100%;
								flex-wrap: wrap;
								
								// al ocultarlo
								overflow: hidden;
								max-height: 2.5em;

								p {
									font-size: 0.85em;
									margin: 0.5em 0.2em;
									padding: 0.2em 0.5em;
									background-color: $color-blue-secondary;
									border-radius: 0.4em;
									color: white;
								}

								.tag--red {
									background-color: $color-danger;
								}
				
								.tag--yellow {
									background-color: #e6a72a;
								}
				
								.tag--green {
									background-color: $color-krino-success;
								}
							}

							.tagsContainer:hover {
								overflow: unset;
								max-height: unset;
							}

						}

					}

					&:hover {
						.users-list-action {
							.new-message-count {
								display: none;
							}

							.action-toggle {
								display: block;
							}
						}
					}
				}
			}

			.NoleadsMessage {
				color: $color-danger;
				text-align: center;
				margin: 2em;
				font-size: 1.4em;
			}
		}

		.chat {
			max-height: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;
			border-right: 1px solid $color-krino-grey2;
			border-left: 1px solid $color-krino-grey2;

			.chat-header {
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid $color-krino-grey2;
				padding: 17px 30px;

				.chat-header-user {
					display: flex;
					align-items: center;

					.infoSection {
						margin-left: 1em;
					}

					figure {
						margin-right: 1rem;
					}

					h5 {
						font-size: $default-font-size + 2;
						font-weight: 600;
						margin-bottom: 0;
						line-height: 1;
					}
				}

				.chat-header-action {
					ul {
						margin-bottom: 0;

						& > li {
							& > a {
								font-size: $default-font-size + 2;
							}
						}
					}
				}
			}

			.chat-body {
				flex: 1;
				padding: 30px;

				&:not(.no-message) {
					overflow: auto;
				}

				&.no-message {
					user-select: none;

					.no-message-container {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						height: 100%;
						text-align: center;

						svg {
							width: 50px;
							height: 50px;
							stroke: $color-primary;
							fill: $style-color;
						}

						p {
							font-size: $default-font-size + 2;
							color: black + 130
						}
					}
				}

				.messages {
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					.message-item {
						word-break: break-all;
						max-width: 75%;
						margin-bottom: 0.5rem;

						&:last-child {
							margin-bottom: 0;
						}

						.message-avatar {
							display: flex;
							align-items: center;
							margin-bottom: 1rem;

							.avatar {
								margin-right: 1rem;
							}

							h5 {
								font-size: 15px;
								margin-bottom: 0;
							}

							.time {
								color: #828282;
								margin-top: 2px;
								font-style: italic;
								font-size: 12px;

								.ti-double-check {
									margin-left: 5px;

									&:before, &:after {
										content: "✔";
									}

									&:after {
										margin-left: -2px;
									}
								}
							}
						}

						.media-iframe {
							margin-top: 0.5em;

							audio {
								display: block;
							}

							iframe {
								border-radius: 0.5em;
							}
						}

						
						.media-linkButton {
							margin-top: 0.7em;
							font-size: 1.2em;
							
							a {
								background-color: $color-primary;
								color: $style-white-secondary;
								border: 0;
								border-radius: 1em;
								padding: 0.3em 0.5em;
							}


						}

						.media-img {
							margin-top: 0.5em;
							background: $color-light-grey;
							border-radius: 0.5em;
							padding: 0.3em;
							max-width: 360px;

							img {
								border-radius: 0.5em;
								width: 100%;
								background: $color-light-grey;
							}
						}

						.message-content {
							background-color: $color-light-grey;
							border-radius: 7px;
							padding: 10px 20px;
							white-space: break-spaces;     /* Permite que el texto se envuelva normalmente */
							word-break: normal;      /* No fuerza la ruptura arbitraria de palabras */
							overflow-wrap: normal;   /* Evita dividir palabras para ajustarlas */
							hyphens: auto;

							&.message-file {
								display: flex;
								word-break: break-word;

								.file-icon {
									font-size: $default-font-size + 10;
									margin-right: 1rem;
									color: black + 130;
								}

								ul {
									margin-top: 3px;
									margin-bottom: 0;

									li.list-inline-item {
										margin-left: 0;
										margin-right: .5rem;
									}
								}
							}
						}

						&.outgoing-message {
							margin-left: auto;

							.message-avatar {
								justify-content: flex-end;
							}

							.media-img {
								margin-left: auto;
								background: $color-blue-secondary;
							}

							.message-content {
								background-color: $color-blue-secondary;
								color: white;
								white-space: break-spaces;     /* Permite que el texto se envuelva normalmente */
								word-break: normal;      /* No fuerza la ruptura arbitraria de palabras */
								overflow-wrap: normal;   /* Evita dividir palabras para ajustarlas */
								overflow-wrap: break-word;
								hyphens: auto;
							}

							.time {
								text-align: right;
							}
						}
					}

					.disconnected-message {
						border-radius: 7px;
						padding: 10px 20px;
						background-color: $color-danger;
						color: white;
						font-weight: 500;
						margin: 0 auto;
					}

					.deleted-message {
						border-radius: 7px;
						padding: 10px 20px;
						background-color: $color-krino-grey2;
						color: white;
						font-weight: 500;
						margin-left: 0;
					}

					.messages-divider {
						width: 100%;
						max-width: 100%;
						position: relative;

						&:before {
							content: attr(data-label);
							display: block;
							position: absolute;
							top: -8px;
							letter-spacing: .5px;
							font-size: 11px;
							padding: 2px 8px;
							border-radius: 3px;
							background-color: $style-color;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}

			}

			.chat-body--loading {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
			}

			.chat-footer {
				border-top: 1px solid $style-color;
				padding: 17px 30px;

				form {
					display: flex;
					align-items: center;
					justify-content: center;

					.form-buttons {
						display: flex;

						.micButton {
							background-color: transparent;
							border: 1px solid #cccccc;
							border-radius: 0.4em;
							margin-left: 1rem;
						}

						.btn {
							margin-left: 1rem;
						}
					}
				}

				.audioPreview {
					display: flex;
					align-items: center;
					justify-content: center;

					audio {
						margin: 0 1em;
					}

					button {
						background-color: transparent;
						border: 1px solid #cccccc;
						border-radius: 0.4em;
						padding: 0.5em;
					}

					.delete {
						color: $color-danger;
					}

					.send {
						color: $color-primary;
					}
				}

				.reactivate__chatButton {
					font-size: 1.2em;
					justify-self: center;
					margin: 0;
					background-color: $color-primary;
					color: white;
					border: 0;
					border-radius: 30px;
					padding: 0.3em 1em;
				}


				.chat-footer__autoComplete {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					flex-direction: column;
					padding: 0em 0.2em;
					padding-bottom: 0.5em;

					.chat-footer__autoComplete__message {
						background-color: #1597E522;
						border: 0;
						border-radius: 0.4em;
						margin: 0.5em 0;
						padding: 0.7em 0.3em;
						font-size: 1em;

						&:hover {
							background-color: $color-primary;
							color: white;
						}

					}
				}


				.liveChat_footer__Loading {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 1em;
				}
			}

			& + .sidebar-group {
				margin-right: 0px;

				.sidebar {
					margin-right: 0px;
				}
			}
		}
	}
}

.position--center {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.tooltip {
	.arrow {
		display: none;
	}

	.tooltip-inner {
		background-color: rgba(black, .5);
	}
}

.kVEayH {
	background: $color-primary !important;
	padding: 0;
	width: 30px !important;
	height: 30px !important;
	line-height: 30px !important;
	box-shadow: none !important;
}

.fKLrJo {
	background: $color-primary !important;
}

.call {
	text-align: center;

	.modal-content {

		.modal-body {
			padding: 50px 0;
		}
	}

	.action-button {
		margin-top: 3rem;

		.btn {
			width: 70px;
			height: 70px;
			margin: 0 15px;

			svg {
				width: 28px;
				height: 28px
			}
		}
	}
}

.GoogleButton {
	font-size: 1.3em;
	padding: 0.3em 2em;
	box-shadow: $krino-boxShadow;
	border: 0.2em solid $color-blue-secondary;
	background-color: white;
	border-radius: 2em;
	font-weight: 500;

	img {
		width: 2em;
		margin-right: 1em;
	}

	&:hover {
		transform: translateY(-2px)
		
	}
}

.sideBar {
	position: fixed;
	top: 0;
	right: -100%;
	height: 100vh;
	min-width: fit-content;
	width: 5em;
	transition: right 0.5s ease-in-out;
	box-shadow: $krino-boxShadow;
	background-color: white;
	overflow: auto;
}

.sideBar--open {
	right: 0
}

.sideBarLeft {
	position: fixed;
	top: 0;
	left: -100%;
	height: 100vh;
	min-width: fit-content;
	width: 5em;
	transition: left 0.5s ease-in-out;
	box-shadow: $krino-boxShadow;
	background-color: white;
	overflow: auto;
}

.sideBarLeft--open {
	left: 100px
}

.datePikerComponent {
	display: flex;

	.datePikerContainer {
		p {
			margin: 0;
			text-align: center;
			color: $color-primary;
			font-size: 1.1em;
			font-weight: 500;
		}

		&:last-child {
			margin-left: 1em
		}
	}
}

.navigationToggleButton {
	position: absolute;
	top: 1em;
	left: 1em;

	button {
		background: $color-primary;
		color: white;
	}
}

// seccion de live chat
.chatsOnHold__section {
	width: 100%;
	.chatsOnHold__list {
		list-style: none;
		padding: 0;
		
		.chatsOnHold__chat {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 1.2em 3em;
			cursor: pointer;
			border-radius: 2em;
			background-color: #308ecb10;
			
			&:not(:last-child) {
				margin-bottom: 1em;
			}

			&:hover {
				transform: scale(1.02);
			}
			
			.chatsOnHold__chat__title {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.chatsOnHold__chat__icon {
				margin: 0;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 3em;
			}

			.chatsOnHold__chat__infoContainer {
				margin: 0;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 3em;
				display: flex;
				flex-direction: column;
				height: 100%;
				justify-content: space-around;
			}
		}

		.chatsOnHold__chat:nth-child(odd) {
			background-color: #308ecb36;
		}
	}
}

.tourButton_container {
	position: fixed;
	top: 1em;
	right: 1em;
	width: 12em;
	height: 5em;

	div {
		padding: 1em;
		width: 12em;
		height: 5em;
		// box-shadow: $krino-boxShadow;
		// background-color: white;
		border-radius: 1em;
		animation: sliceLeft 0.5s linear;
		position: absolute;
		top: 0%;
		right: -100%;
		// transform: translate(50%, -50%);
		animation-fill-mode: forwards;

		button {
			width: 10em;
			// animation: sliceLeft 1s linear;
			// position: absolute;
			// top: 50%;
			// right: -100%;
			// transform: translate(50%, -50%);
			// animation-fill-mode: forwards;
		}
	}

	

}

.pageLoadingSpinner {
	// margin del ancho del menu
		margin-left: 50px;
}


@keyframes sliceLeft {
	0% {
		right: -100%;
	}

	100% {
		right: 0;
	}
}

@import "Responsive";
