.homePage {
  width: 100%;
  padding: 1em 2em;
  padding-bottom: 1em;
  height: fit-content;
  min-height: 100%;

  .homePage__Nopermiso {
    padding: 2em;

    h1 {
      color: $color-primary;
      text-align: center;
    }
  }

  .BotBuilderSelection {
    width: 100%;
  }

  .homePage__chatSection {
    margin: 4em 0;

    .homePage__chatContainer {
      width: 80%;
      margin: 0 auto;

      .chatsOnHold__chat__title {
        margin: 0;
      }
    }
  }

  .homePage__botSection {
    margin: 1em auto;
    margin-bottom: 2em;
  }

  .homePage__metricSection, .homePage__chatSection{
    header {
      h2 {
        color: $color-blue-secondary;
        font-weight: 500;
      }
    }
    .backgroundBox a {
      text-align: center;
      margin: 1em auto;
      display: block;
      max-width: 17em;
    }
  }

  .dashboardContainer {
    min-height: 30em;
    height: 40em;
    overflow: hidden;
  }
}