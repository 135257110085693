.billing__main {
  padding: 2em;

  .billing__section {
    margin: 2em;
    width: 40em;
    
    header {
      margin-bottom: 1em;
    }

    .billingContainer {
      padding: 2em;
      font-size: 1.2em;

      .billingContainer__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5em;
        font-weight: 500;
      }
    }
  }

}

