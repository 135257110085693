.payment__main {
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 2em 0;


  .payment__logo {
    margin: 2em auto;
    display: block;
  }

  .payment__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 80%;
    min-width: 1200px;
    min-height: 70vh;
    height: fit-content;
    min-height: fit-content;
    padding: 3em 0;

    .payment__box__title {
      margin-bottom: 1em;
      text-align: center;
      color: $color-primary;
    }

    .payment__cardsContainer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
}

.paymentCard {
  background-color: $color-krino-white;
  width: 25em;
  padding: 1em;
  margin: 1em;
  text-align: center;
  box-shadow: $krino-boxShadow;
  border-radius: 30px;

  &:hover {
    transform: scale(1.05);
  }

  .paymentCard__title {
    color: $color-blue-secondary;
  }

  .paymentCard_description {
    white-space: break-spaces;
    font-size: 1.1em;

  }

  .paymentCard__descriptionSection {
    text-align: left;
  }

  // .krino__button {

  // }
}


// payment status 

.paymentStatus__main {
  width: 100%;

  .paymentStatus__infoContainer {
    margin: 0 auto;
    width: 40em;
    text-align: center;
 
    img {
      width: 300px;
      margin: 2em 0;
    }

    h3 {
      font-size: 4em;
    }

    p {
      font-size: 1.5em;
      margin: 1em 0;
    }

    a {
      display: inline-block;
      font-size: 1.3em;
      padding: 1em 4em;
    }

  }

}