.leadControlPanel {
  width: 100%;

  header {
    padding: 1em 2em;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
 
    .temperatureLead_listOfLeads_headerLeft {
      display: flex;
      align-items: center;
      
      h3 {
        margin: 0;
      }
      
      .temperatureLead_listOfLeads_pageSelectorContainer {
        // margin: 0 1em;

        button {
          margin: 0 1em;
        }
      }
    }
 
    .leadControlPanel__title {
      color: $color-blue-secondary;
      font-size: 1.5em;
      margin: 0;
    }

    .leadControlPanel__configButton--disable {
      cursor: unset;
      background-color: $color-krino-grey;

      &:hover {
        background-color: $color-krino-grey;
        transform: scale(1);
      }
    }
  }

  .leadControlPanel_chart {
    width: 100%;
    height: 50vh;
  }

  .temperatureLead_listOfLeads {
   width: 100%;
   padding: 3em 2em 0 2em;
    header {
      padding: 0;
    }

    .temperatureLead_listOfLeads_spinner{
      margin: 4em auto;
      text-align: center;
    }

    .temperatureLead_listOfLeads_table {
      margin: 1em 0;
      width: 100%;

      thead {
        background-color: white;
        position: sticky;
        top: 0;
      }

      .selectedHeader {
        color: $color-blue-secondary;
      }

      tr {
        border-bottom: 1px solid $color-krino-grey2;
      }

      th {
        cursor: pointer;
        padding: 1em 0;

        &:hover {
          color: rgba($color: $color-blue-secondary, $alpha: 0.5);
        }
      }

      td {
        padding: 1em 0;
      }

      thead {
        th {
          color: $color-krino-grey2;
        }
      }

      tbody tr {
        cursor: pointer;
        &:hover {
          background-color: rgba($color: $color-primary, $alpha: 0.2);
        }
      }

      .tableItem {
        h5 {
          font-size: 1.1em;
          margin: 0;
        }
      }

      .tableItem__customColum p{
        max-width: 12em;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: 0;
        display: inline-block;

      }

      // .plaOK {
      //   h5 {
      //     background-color: $color-krino-success;
      //     border-radius: 0.4em;
      //     width: fit-content;
      //     color: white;
      //     padding: 0.4em 0.8em;
      //   }
      // }

      // .planOk--high h5{
      //   background-color: $color-danger;
      // }

      // .planOk--low h5{
      //   background-color: $color-warning;
      // }

      // .Krino_temperature {
      //   color: $color-krino-success;
      //   font-size: 1.5em;
      //   font-weight: bold ;
      // }

      // .Krino_temperature--low {
      //   color: $color-warning;
      // }

      // .Krino_temperature--high {
      //   color: $color-danger;
      // }

    }
  }
}

.leadControlPanel__configButton{
  box-shadow: $krino-boxShadow;
  padding: 0.5em;
  border-radius: 0.4em;
  font-weight: 500;
  border: 0;
  background-color: transparent;
  span {
    margin-right: 0.5em;
  }

  &:hover {
    background-color: rgba($color:$color-primary, $alpha: 0.3);
    transform: scale(1.02);
  }
}

.leadControlPanel__configButton--selected {
  background-color: $color-blue-secondary !important;
  color: white;
  box-shadow: $krino-boxShadow;
  padding: 0.5em;
  border-radius: 0.4em;
  font-weight: 500;
  border: 0;
  background-color: transparent;

}

.plaOK {
  h5 {
    background-color: $color-krino-success;
    border-radius: 0.4em;
    width: fit-content;
    color: white;
    padding: 0.4em 0.8em;
  }
}

.planOk--high h5{
  background-color: $color-danger;
}

.planOk--low h5{
  background-color: $color-warning;
}

.planOk--none h5{
  background-color: $color-krino-grey2;
}

.planOk--fill h5{
  background-color:   $color-krino-success;
}
.Krino_temperature {
  color: $color-krino-success;
  font-size: 1.5em;
  h5 {
    font-weight: bold;
  }
}

.Krino_temperature--low {
  color: $color-warning;
}

// .Krino_temperature--mid {
//   color: $color-danger;
// }

.Krino_temperature--high {
  color: $color-danger;
}

.Krino_temperature--none {
  color: $color-krino-grey2;
}

.leadSideBar {
  position: relative;
  padding: 1em;
  min-width: 40em;
  max-width: 70vw;
  text-align: center;
  padding-top: 2em;

  .leadSideBar__exitButton {
    position: absolute;
    top: 0.3em;
    left: 0.3em;
    background-color: transparent;
    border: 0;
  }

  .leadSideBar_generalInfoConainer {
    .leadSideBar_generalInfoConainer__name {
      margin: 0;
      margin-top: 0.5em;
      font-size: 2em;
    }

    .leadSideBar_generalInfoConainer__botName {
      color: $color-krino-grey2;
      margin: 0;
      margin-bottom: 0;
    }

    .leadSideBar_generalInfoConainer__temperatureSection {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .leadSideBar_generalInfoConainer__temperatureConteiner {
        width: 10em;

        p {
          color: $color-krino-grey2;
          font-size: 1.2em;
          font-weight: 500;
        }

        h5 {
          margin: 0 auto;
        }
      }
    }
  }
  .leadSideBar_ChatInfoContainer {
    margin: 1em 0;
    .leadSideBar_ChatInfoContainer__container {
      padding: 0.5em;
      box-shadow: $krino-boxShadow;
      border-radius: 0.4em;

      &:not(:last-child) {
        margin-bottom: 1em;
      }

      .leadSideBar_ChatInfoContainer__container__title{
        text-align: left;
        color: $color-primary;
        font-size: 1.6em;
        margin-bottom: 1em;
      }

      .leadSideBar_ChatInfoContainer__Notes {
        textarea {
          padding: 0.5em;
          width: 100%;
          resize: none;
          border-radius: 0.4em;
          border: 0.05em solid $color-krino-grey2;

          &:focus {
            outline-color: $color-primary;
          }
        }
      }

      .leadSideBar_ChatInfoContainer__vars {
        display: block;
        max-height: 15em;
        overflow-y: auto;

        ul {
          list-style: none;
          padding: 0;
        }
      }
    }
  }

  .leadSideBar_actionsContainer {
    .leadSideBar_actionsContainer_title {
      color: $color-primary;
    }

    .leadSideBar_actionsContainer_buttonContainer {
      display: flex;
      align-items: center;
      flex-direction: column;

      button {
        min-width: 60%;
        font-size: 1.1em;
        font-weight: 500;

        &:not(:last-child) {
          margin-bottom: 1em;
        }

        &:hover {
          transform: scale(1.03);
        }
      }

      
    }
  }
}

.temperatureLead_listOfLeads_filter {
  margin: 2em 0 1em 0;
  padding: 1.5em 1em;
  box-shadow: 0px 2px 4px 0px rgba(21, 151, 229, 0.5);
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  border-radius: 0.4em;

  .filterButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
  }

  .temperatureLead_listOfLeads_filter_container {
    flex-grow: 1;
  }

  .temperatureLead_listOfLeads_filter__section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 1em 0;
    position: relative;
    border-bottom: 0.05em solid $color-krino-grey2;

    .temperatureLead_listOfLeads_filter__sectionTitle {
      position: absolute;
      top: 0.5em;
      left: 0.5em;
      color: $color-primary;
      font-weight: 500;
      font-size: 1.2em;
    }
    

    &:first-child {
      padding-top: 1.9em;
    }

    &:last-child {
      padding-top: 3.2em;
      justify-content: flex-start;
      padding-left: 1em;
    }
  }

  .temperatureLead_listOfLeads_filter__filterContainer {
    p {
      margin-bottom: 0;
      font-size: 1.1em;
      font-weight: 500;
      color: $color-blue-secondary;
    }

    .selectContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5em;

      button {
        cursor: pointer;
        background-color: transparent;
        border: 0;
        font-size: 1.7em;
        font-weight: 500;
        color: $color-danger;
      }
    }
  }

  .temperatureLead_listOfLeads_filter__filterContainer__average {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    gap: 0.5em;
    width: 16em;

    button {
      width: 48%;
    }

  }

  .temperatureLead_listOfLeads_filter__contextFilterList {
    padding: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 1em;

      p {
        margin: 0;
      }

      .container {
        width: 12em;
        margin: 0 1em;
      }

      .columName {
        font-weight: 500;
        font-size: 1.1em;
      }

      .temperatureLead_listOfLeads_filter__contextFilterList__header {
        font-weight: 500;
        color: $color-blue-secondary;
      }

      .krino__button {
        font-size: 0.9em;
      } 
    }

    .disableItem {
      p, .krino__button {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

}

.temperatureLead_listOfLeads_columns {
  margin: 2em 0 1em 0;
  padding: 1.5em 1em;
  box-shadow: 0px 2px 4px 0px rgba(21, 151, 229, 0.5);
  border-radius: 0.4em;

  .temperatureLead_listOfLeads_columns__subTitle {
    margin-bottom: 1em;
    color: $color-primary;
    font-size: 1.6em;
  }

  .columnsContianer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  
    .columnsContianer__item {
      display: flex;
      align-items: center;

      p {
        margin: 0;
        font-size: 1.4em;
        font-weight: 500;
        color: $color-blue-secondary;
      }

      button {
        background-color: transparent;
        color: $color-danger;
        border: 0;
      }
    }
  }

  .saveButton {
    display: block;
    margin: 0 auto;
    margin-top: 2.2em;
  }
}