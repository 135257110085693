.faqSelectionSection__formNewSection {
  width: 100%;
  text-align: center;

  .title {
    color: $color-primary;
  }

  h5 {
    margin: 0;
    margin-top: 2em;
    font-size: 1em;
  }

  input {
    margin: 0 auto;
    width: 100%;
  }

  button {
    margin-top: 2em;
  }
}