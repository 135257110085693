$header-height: 60px;

$style-color: #EEEEEE;
$style-white-secondary: #f4f7f5;

$color-primary: #1597E5;
$color-blue-secondary: #204191;
$color-secondary: white - 150;
$color-success: #69DADB;
$color-info: #3399cc;
$color-light: white - 25;
$color-danger: #cc3e34;
$color-warning: #FFC043;
$color-dark: #333333;
$color-krino-success: #06C167;

$krino-gradient: linear-gradient(to left, $color-blue-secondary, $color-primary);

$color-krino-white: #fefefe;
$color-krino-grey2: #C4C4C4;
$color-krino-grey: #f3f3f3;
$color-light-grey: #e2e2e2e5; 
$color-light-blue: #D2E8EC;

$krino-boxShadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);

$color-primary-bright: #c0c5e4;
$color-secondary-bright: #ededed;
$color-success-bright: #cef5ea;
$color-info-bright: #e1efff;
$color-danger-bright: #fcd0df;
$color-warning-bright: #ffe6cc;
$color-dark-bright: #d4d5d8;

$color-facebook: #3b5998;
$color-twitter: #55acee;
$color-google: #db4437;
$color-pinterest: #bd081c;
$color-linkedin: #0077b5;
$color-tumblr: #35465c;
$color-instagram: #3f729b;
$color-dribbble: #ea4c89;
$color-youtube: #cd201f;
$color-whatsapp: #43d854;
$color-github: #00405d;
$color-behance: #1769ff;
$color-skype: #00aff0;
$color-yahoo: #410093;
$default-avatar-size: 2.3rem;
$default-font-size: 14px;
