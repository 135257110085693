.toc__main {
  width: 80%;
  height: 80vh;
  margin: 2em auto;
  text-align: center;

  .toc__Content {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .toc__checkContainer {
    display: flex;
    align-items: center;
    margin-left: 0.25in;

    input {
      border-radius: 1em;
      margin-right: 1em;
    }

    p {
      margin: 0;
    }
  }

  .toc__button {
    margin-bottom: 2em;
  }
}



