.errorPage {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .errorPage__section {
    width: 70%;
    text-align: center;

    .errorPage__img {
      width: 30em;
      max-width: 90%;
      display: block;
      margin: 0 auto;
      // margin-left: 10%;
    }

    .errorPage__title {
      font-size: 4em;
      color: $color-danger;
    }

    .errorPage__description {
      color: $color-blue-secondary;
      font-size: 1.2em;
      margin: 1em 0;
      font-weight: 500;
    }
  }
}