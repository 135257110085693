.chatLoading--spiners {
  margin-top: 2em;
  .spinner {
    width: 2rem;
    height: 2rem;
    animation-delay: 0s;
    animation-duration: 1.5s;
  }

  .spinner:nth-child(2) {
    animation-delay: 0.25s;
    margin: 0 1em;
  }

  .spinner:last-child {
    animation-delay: 0.5s;
  }
}

.chatLoading--spiners--nospacing {
  margin: 0;
}

body.dark {
  .chatLoading--message {
    color: $style-white-secondary;
  }  
}

.chatLoading--message {
  margin: 0 auto;
  margin-top: 2em;
  font-weight: 500;
  color: $color-dark;
}

.chatLoading--error {
  margin: 0 auto;
  font-weight: 500;
  color: $color-danger
}