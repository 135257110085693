// .chatsOnHoldContainer{
//   overflow-y: auto !important;
// }
.chatsFilterContainer {
  width: 100%;
  padding: 0 0.5em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  .chatsFilterContainer__buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      border-radius: 0.4em;
      margin: 0 0.5em;
    
      &:hover {
        transform: scale(1.03);
      }
    }
  }

  .chatsFilterContainer__status {
    color: $color-primary;
  }

  .chatsFilterContainer__endButtonContainer {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 1em;

    button {
      margin: 0 0.5em;
    }
  }

  .react-datepicker-wrapper {
    text-align: center;
  }
  .chatsFilterElement {
    margin: 0.5em 0;

    .chatsFilterButtonsContainer {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      max-height: 10em;
      overflow: auto;
      
      .filterButton {
        margin: 0.5em;
        border: 0;
        border-radius: 2em;
        padding: 0.3em 0.6em;
        background-color: $color-krino-grey;
        box-shadow: $krino-boxShadow;
        width: 44%;
        word-break: break-all;
        &:hover {
          background-color: rgba($color: $color-primary, $alpha: 0.5);
        }
      }

      .filterButton--selected {
        background-color: $color-primary;
        color: white;
        
        &:hover {
          background-color: $color-krino-grey2;
          color: black;
        }
      }
    }
  }
}

// admin button
.admin__button {
  font-size: 0.5em;
  margin-left: 1em;
} 

.executiveSchedule {
  height: fit-content;
  overflow-x: auto;
  padding: 0.5em;

  .executiveSchedule__title {
    color: $color-primary;
    font-weight: 500;
    font-size: 1.2em;
    margin-bottom: 0.5em;
    
  }

  .executiveSchedule__container {
    padding: 1em;
    min-width: 500px;


    .executiveSchedule__list {
      list-style: none;
      padding: 0;

      .executiveSchedule__item {
        display: flex;
        justify-content: space-between;

        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        button {
          margin-right: 1em;
        }

        input {
          padding: 0.3em;
        }

        p {
          display: inline;
          margin: 0 0.7em;
          font-size: 1.4em;
        }

        &:not(:last-child) {
          margin-bottom: 1em;
        }

      }
    }
  }
}

.liveChatAdmin {
  width: 100%;
  // display: flex;
  // justify-content: space-between;
  display: table;
 
  main {
    display: table-cell;
    width: 100%;
    padding: 1.5em;
  }

  .liveChatAdmin__backArrow {
    display: block;
    width: fit-content;
    margin-bottom: 1.5em;
    color: $color-primary !important;
  }

  .liveChatAdmin__asideMenu {
    display: table-cell;
    // display: flex;
    // flex-direction: column;
    align-items: center;
    border-left: 0.1em solid rgba($color: #000000, $alpha: 0.5);
    padding: 1em 0;
    color: $color-primary;

    .liveChatAdmin__asideMenu__container {
      position: sticky;
      top: 0;
    }

    header {
      width: 100%;
      border-bottom: 0.1em solid rgba($color: #000000, $alpha: 0.5);
      text-align: center;
      padding: 1em;
      display: flex;
      align-items: center;
      justify-content: center;

      h5 {
        margin: 0;
      }

      .liveChatAdmin__asideMenu__toggleMenu {
        // display: none;
        margin-left: 1em;
      }
    }

    nav {
      padding: 1em;
      display: flex;
      flex-direction: column;

      a {
        margin-bottom: 1em;
        color: $color-primary;
        font-size: 1.1em;
        text-align: center;
      }
    }
  }

  .liveChatAdmin__asideMenu--hamburgerMenu {
    position: fixed;
    top: 1em;
    right: 1em;
    border-radius: 0.5em;
    padding: 0.25em 0.5em ;
    box-shadow: $krino-boxShadow;
    border: 0.2em solid $color-blue-secondary;

  }

  .liveChatAdmin__homeSection {
    .chatsContainer {
      margin: 1.5em 0;

      .chatsContainer__title {
        color: $color-primary;
      }
      .chatsContainer__chatsContainer {
        overflow: hidden;
      }
      .chatsContainer__list {
        list-style: none;
        padding: 0;


        .chatsContainer__item {
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;
          padding: 1.5em 1em;
          background-color: #e9e9e9;
          
          figure {
            margin-right: 2em;
          }

          .date {
            position: absolute;
            top: 0.8em;
            right: 1em;
            font-size: 1.1em;
          }


          .origin {
            position: absolute;
            bottom: 0.8em;
            right: 1em;
            font-size: 1.1em;
          }

          &:nth-child(2n) {
            background-color: #D2E8EC;
          }

          &:hover {
            transform: scale(1.005);
          }

          .chatsContainer__item__content {
            display: flex;
            align-items: center;
          
            h5 {
              margin: 0;
            }
          }
        }

        .chatsContainer__defaultItem {
          text-align: center;
          padding: 1em 0;

          p {
            margin: 0;
            font-weight: 500;
            font-size: 1.3em;
          }
        }
      }
    }
  }

}

.liveChatAdmin__modalKeyWords {
  padding: 1em;
  text-align: center;
  .title {
    text-align: center;
  }

  .mainContainer {

    .description {
      color: $color-primary;
      text-align: center;
    }
    .mainContainer__list { 
      list-style: none;
      text-align: center;
      
      .mainContainer__item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
        
        p {
          margin: 0;
          margin-right: 1em;
        }

        .addWordContainer {
          display: flex;
          input {
            margin-right: 1em;
          }  
        }
      }
    }
  }
}

// chat data modal 
.chatDataModal {
  padding: 1em;

  .selectTemplate {
    margin: 2em 0;
    text-align: center;
    
    p {
      color: $color-primary;
    }

    select {
      margin: 0 auto;
    }
  }

  .submit__button {
    margin: 0 auto;
    display: block;
  }

  h1 {
    text-align: center;
    color: $color-primary;
  }

  .chatDataModal__chatActionsContainer {
    width: 100%;
    padding: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1em;
  }

  .chatDataModal__mainContainer {
    display: flex;
    justify-content: space-between;
    margin: 2em 0;
    
    .chatDataModal__section {
      width: 45%;
      h3 {
        text-align: center;
        color: $color-primary;
      }
    }

    .chatDataModal__section--variables {
      ul {
        padding: 0;
        list-style: none;

        li {
          margin: 1em 0;
          
          p {
            margin: 0;
            word-break: break-all;
          }
        }
      }
    }

    .chatDataModal__section--variables {
      margin: 0;
    }
  }

  .chatDataModal__executivesContainer {
    margin: 3em 0;
    .chatDataModal__executivesContainer__title, .chatDataModal__executivesContainer__description {
      text-align: center;
      color: $color-primary;
    }

    .chatDataModal__executivesContainer__list {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;

      .chatDataModal__executivesContainer__item {
        box-shadow: $krino-boxShadow;
        border-radius: 0.3em;
        margin: 1em;
        cursor: pointer;
        padding: 1em;
        width: 15em;
        min-width: fit-content;

        &:hover {
          background-color: $color-krino-grey;
          transform: scale(1.009);
        }

        .chatDataModal__executivesContainer__item__infoSection {
          display: flex;
          justify-content: space-between;
          align-items: center;
          figure {
            box-shadow: $krino-boxShadow;
          }

          .chatDataModal__executivesContainer__item__infoSection__info {
            text-align: center;
            p {
              color: $color-blue-secondary;
              margin: 0;
              font-size: 1.3em;
            }
          }
        }

        .chatDataModal__executivesContainer__item__schedule {
          text-align: center;
          margin: 1.1em;

          p {
            color: $color-primary;
            margin: 0;
            font-size: 1em;
          }
        }
      }

      .chatDataModal__executivesContainer__item--selected {
        background-color: rgba($color: $color-primary, $alpha: 0.3);
      }
      
    }
  }
}

.chatDataModal__section__loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 0;
}