.campaignMain {
  width: 100%;
  height: 100%;
  padding: 2em 0em;
  overflow: auto;

  section {
    margin: 2em auto;
    width: 87vw;
  }

  .loadingContainer {
    text-align: center;
    margin: 1em 0;
  }
}

.tableContainer {
  max-height: 27em;
  overflow: auto;
}

.campaignMain__sectionTitle {
  color: $color-blue-secondary;
  font-weight: 500;
}

.campaignMain__errorMessage {
  color: $color-danger;
  font-size: 1.2em;
  margin: 1em;
  margin-left: 1em;
}

.backgroundBox {
  background-color: #F3F3F3;
  box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
  border-radius: 30px;
  padding: 1em;
}

// .campaignMain__table {
//   margin-top: 1em;
//   width: 100%;
//   // padding: 1em;

//   thead {
//     width: 50px;
//     margin: 2em 0 ;
//   }

//   tbody {
//     tr {
//       cursor: pointer;
    
//       &:nth-child(odd) {
//         background-color: #308ecb36;
//       }
//     }
//   }

//   th {
//     padding: 1em;
//   }

//   td {
//     padding: 1em;
//   }
// }

  // templates wsp

.campaignMain__campaignsWspSection {
  .backgroundBox {
    width: 40em;
    height: 20em;
  }

  .templateText__container {
    position: relative;
    width: 100%;
    height: 87%;
    background-color: #308ecb36;
    padding: 1em;
    border-radius: 30px;
    white-space: pre-wrap;
    overflow: auto;

    p {
      font-size: 1.2em;
      margin: 0;
    }

    .buttonsContainer {
      position: absolute;
      bottom: 0.6em;
      right: 0.6em;

      a {
        color: $color-blue-secondary;
      }

      button {
        border: 0;
        background-color: transparent;
      
        &:first-child {
          color: black;
        }

        &:last-child {
          color: $color-danger;
        }
      } 

      
    }
  }
  .title__button__container {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    .campaignMain__button {
      margin-left: 3em;
    }
  }

  .campaignsWsp__markerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    margin: 1em 0;
    width: 100%;
  }

  .campaignsWsp__marker {
    cursor: pointer;
    background-color: #C4C4C4;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin: 0 1em;
  }

  .campaignsWsp__marker--active {
    background-color: $color-primary;
  }

  .wspTemplatesCarrousel__container {
    width: 87vw;
    border-radius: 3em;
    padding: 1em;

    .wspTemplatesCarrousel {
      display: flex;
      align-items: center;
      width: 100%;
      overflow: visible;
      overflow-x: auto;
      padding-bottom: 1em;
      
      .wpsTemplatesCarrousel_item {
        min-width: 40em;
        margin: 0 0.5em;
      }
      
      .noTemaplatesMessage {
        margin: 1em 0 1em 4em ;
        font-size: 1.2em;
        color: $color-danger;
      }
    }
  }
}


//new campaign 

.newCampaign__main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h3 {
    margin-top: -4em;
    margin-bottom: 1em;
    text-align: center;
    color: $color-blue-secondary;
  }
}

.newCampaign__main__section {
  padding: 2em;
  width: 50em;
  text-align: center;

  h4 {
    font-size: 1.2em;
    margin: 0;
  }

  input {
    width: 80%;
    height: 3em;
    padding: 1em;
    border-radius: 30px;
    border: 0;
    box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
    margin: 2em 0;

    &:focus {
      outline-color: $color-primary;
    }
  }
  
  select {
    display: block;
    margin: 1em auto;
    width: 80%;
    height: 3em;
    border-radius: 30px;
    border: 0;
    padding: 0 2em;
    box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 7em;
      margin: 0.5em;
      border: 0;
    }
  }
}
.campaigns__StepMarker__container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 120%;
  display: flex;
  justify-content: center;
  

  .campaigns__StepMarker {
    display: block;
    background-color: #C4C4C4;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin: 0 5em;
  }
  
  .campaigns__StepMarker--active {
    background-color: $color-primary;
  }
}

// modales 

.newTemplate__modal {
  
  h4 {
    text-align: center;
  }

  textarea {
    display: block;
    margin: 2em auto;
    width: 100%;
    border-radius: 30px;
    padding: 1em;
  }

  button {
    display: block;
    margin: 0 auto;
  }
}

// info 
.campaignInfo_main {
  width: calc(99% - 100px);
  
}

.campaignInfo_tablehead {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    margin-right: 0.3em;
  }
}



.campaignInfo_section {
  display: block;
  width: 87%;
  margin: 2em auto;

  .back__button {
    display: block;
    margin-bottom: 1em;
    width: fit-content;
  }

  .table_container {
    width: 100%;
    overflow: auto;
    box-shadow: $krino-boxShadow;
    border-radius: 30px;
    max-height: 68vh;
    background-color: $color-krino-grey;

    .krino__table {
      white-space: nowrap;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: none;
    
      tbody tr:last-child() {
        border-bottom: 0.05em solid $color-primary;

        .campaignInfo_lead_messageReason__message {
          top: unset;
          bottom: 120%;
        }
      }
    }
  }

  .addLeadButton {
    margin: 0.6em auto;
    display: block;
  }

  .campaignInfo_section__loadingContainer {
    text-align: center;
    margin-top: -4em;
    margin-bottom: 2em;

    h4 {
      color: $color-blue-secondary;
    }
  }
  .campaignInfo_section_headerLeftContainer {
    display: flex;
    align-items: center;
    justify-content:  left;

    .campaignInfo_section_paginationContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5em;
      margin-left: 1em;

      p {
        margin: 0 1em;
      }
    }
  }
}

.campaignInfo_buttonsSection {
  margin: 0 auto;
  width: 87%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.campaignInfo__sendModal {
  .campaignInfo__sendModal__title {
    text-align: center;
    color: $color-primary;
  }

  .campaignInfo__sendModal__selectType {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
  
    span {
      display: block;
      margin: 3em 0;
    
      button {
        margin-bottom: 1em;
      }

      p {
        font-weight: 500;
      }
    }
  }

  .campaignInfo__sendModal__advanceOptions {
    .campaignInfo__sendModal__advanceOptions__description {
        font-weight: 500;
        font-size: 1.1em;
        color: $color-primary;
        text-align: center;
    }

    input {
      margin-left: 1em;
      border-radius: 0.4em;
      border: $color-krino-grey2 solid 0.1em;
      box-shadow: $krino-boxShadow;
      padding: 0.6em 0.4em;
      min-width: 20em;

      &:focus {
        outline: $color-primary solid 0.1em;
      }
    }

    ul {
      padding: 1em;
      list-style: none;
    
      li {
        margin-bottom: 1em;
        box-shadow: $krino-boxShadow;
        padding: 1.4em 1em;
        display: flex;
        align-items: center;
      
        &:hover {
          background-color: $color-krino-grey2;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 1.1em;
        }
      }
    }

    .errorMessage {
      color: $color-danger;
      font-weight: 500;
      font-size: 1.1em;
    }
  }
}

.campaignInfo_lead_messageState {
  display: flex;
  align-items: center;

  .campaignInfo_lead_messageReason {
    position: relative;
    background-color: $color-dark;
    border-radius: 50%;
    color: white;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    margin-left: 0.5em;
    padding-left: 1px;
    cursor: pointer;

    .campaignInfo_lead_messageReason__message {
      position: absolute;
      top: 120%;
      box-shadow: $krino-boxShadow;
      border-radius: 0.4em;
      text-align: center;
      background-color: white;
      color: black;
      left: 50%;
      transform: translateX(-50%);
      padding: 1em;
      min-width: 23em;
      
      p {
        margin: 0;
      }
    }
  }
}