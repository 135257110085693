.templatesContainer {
  display: flex;
  padding: 0 2em;
  width: 100vw;
}

.templates__main {
  flex-grow: 1;
  width: 100%;
  // width: 84vw;
  // min-width: 49em;
  // max-height: 80vh;
  display: flex;
  border: 0.3em solid $color-blue-secondary;
  border-radius: 35px;
  margin: 3em auto;
  overflow: hidden;

  & .templates__menuSection header, & .templates__cardSection > header{
    background: $color-primary;
    color: white;
    height: 6em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em;
    box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
  }



  .templates__menuSection {
    header {
      border-top-left-radius: 30px;

      h3 {
        white-space: nowrap;
      }
    }

    ul {
      height: 100%;
      border-right: 2px solid $color-blue-secondary;
      list-style: none;
      padding: 1em 0;


      li {
        margin-bottom: 1em;
        cursor: pointer;
        color: rgba($color: $color-blue-secondary, $alpha: 0.5);
        font-weight: 500;
        font-size: 1.2em;
        text-align: center;
      }

      .item--selected {
        color: $color-blue-secondary;
      }
    }
  }

  .templates__cardSection {
    width: 100%;
    & > header {
      border-top-right-radius: 30px;
      
      .templates__infoSection {
        position: relative;

        p {
          position: absolute;
          white-space: nowrap;
          bottom: -1.9em;
        }
      }


      a {
        background-color: $style-white-secondary;
        color: $color-primary;
        border: 0;
        border-radius: 2em;
        padding: 0.5em 1em;
      }
    }

    .cardContainer {
      display: flex;
      flex-wrap: wrap;
      padding: 1em;
      overflow: scroll;
      height: 100%;
      padding-bottom: 10em;
    
      .cardContainer__noTemplatesMessage {
        display: flex;
        margin: 3em auto;
        color: $color-primary;
      }
    
    }

    
  }

}

.templateCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 21em;
  margin: 1em auto;
  padding: 0.7em;
  text-align: center;
  background-color: rgba($color: $color-krino-grey2, $alpha: 0.2);
  border-radius: 2em;
  height: fit-content;
  min-height: 100%;
  box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);

  .img {
    width: 12em;
    height: 11em;
    border-radius: 40px;
    box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.25);
  }

  .title {
    margin: 1em;
    color: $color-primary;
  }

  .description {
    white-space: break-spaces;
    color: rgba($color: #203191, $alpha: 60);
  }

  .info {
    font-weight: 300;
    font-size: 0.9em;
    color: rgba($color: #203191, $alpha: 60);
  }

  button {
    border-radius: 3em;
    background-color: $color-primary;
    color: white;
    padding: 0.5em 2em;
    border: 0; 
  }

}

.improve-bot__section {
  height: 80%;
  margin: 0 auto;
  margin-top: 1em;
  display: flex;

  .iframe__container {
    position: relative;
    background-color: rgba($color: $color-krino-grey2, $alpha: 0.4);
    width: 28em;
    border-radius: 30px;
    height: 100%;
    margin: 0 auto;
  
    .dnnoPQOJXG {
      z-index: 2;
      position: absolute;
      top: unset;
      bottom: 0;
      left: unset;
      right: 50%;
      transform: translateX(50%);
      max-height: 460px;
    }
    #divimprovebot_calltoaction_general {
      display: none;
    }
  }

  .buttonsContainer {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    margin: 2em 0;
    width: 50%;
    margin-right: auto;

    .loadingContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;

      h6 {
        margin: 1.5em 0;
      }
    }

    button {
      display: block;
      margin: 1em 0.5em;
    }
    
    // .krino__button {
    //   display: block;
    //   margin: 2em auto;
    // }
  }

}

.copyBotLoadingModal {
  padding: 1em;

  .copyBotLoadingModal__title {
    font-size: 1.7em;
    text-align: center;
    color: $color-primary;
    margin: 1em;
  }
  
}


// config
.templateConfig{
  width: 100%;
  padding: 2em;

  .templateConfig__title {
    color: $color-primary;
    margin: 1.5em 0;
    text-align: center;

    span {
      color: $color-blue-secondary;
    }
  }

  .templateConfig_configSection {
    padding: 1em;

    .configSection_title {
      color: $color-primary;
    }

    .configSection_description {
      color: $color-blue-secondary;
      font-weight: 500;
    }

    .templateConfig_customValuesContainer {
      width: 100%;

      .templateConfig_customValuesList {
        padding: 0;
        list-style: none;

        .templateConfig_customValuesItem {
          width: 100%;
          padding: 1em;
          border-radius: 0.4em;

          &:nth-child(odd) {
            background-color: #308ecb36;
          }

          .templateConfig_customValuesItemInfo {
            display: flex;
            align-items: center;
            justify-content: space-between;
          
            .templateConfig_customValuesItemvalue {
                margin: 0;
                font-weight: 500;
                font-size: 1em;
            }
          }

          .templateConfig_customValuesItemEdit {
            display: flex;
            align-items: center;
            justify-content: space-between;

            input {
              border-radius: 0.4em;
            }
          }
        }
      }

      .templateConfig_customValues_createButton {
        display: block;
        margin: 0 auto;
      }
    }

  }

  .saveButton {
    display: block;
    margin: 1em auto;
  }

}