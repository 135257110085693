.templatesConstructor__main {
  width: 100%;
  padding: 4em;
  display: flex;
  flex-flow: column;
  height: 100%;

  .backButton {
    width: fit-content;
  }
}

.templatesConstructor__configSection__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 2em 0;
  margin-bottom: 2.5em;

  .templatesConstructor__configSection {  
    .templatesConstructor__configSection__description {
      font-size: 0.9em;
      font-weight: 500;
      color: $color-primary;
      margin: 0;
    }
  }
}

.templatesConstructor__border {
  border: $color-krino-grey2 solid 1px;
  padding: 0.5em 0.7em;
  border-radius: 0.4em;
}

.templatesConstructor__supplier {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: $krino-boxShadow;
  width: 20em;

  .supplier__name {
    font-weight: 500;
    font-size: 1.2em;
    margin: 0 0.5em;
  }
}

.templatesConstructor__supplier__button {
  border: 0;
  width: 4em;
  height: 2em;
  background-color: $color-krino-grey2;
  border-radius: 3em;
  transition: background-color 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.templatesConstructor__supplier__button--active {
  background-color: $color-primary;
  justify-content: flex-end;
}

.templatesConstructor__supplier__button__switch {
  transition: background-color 0.5s ease-in-out;
  width: 1.1em;
  height: 1.1em;
  background-color: white;
  border-radius: 100%;
}

.templatesConstructor__botSelector__container {
  width: 100%;
  max-width: 100%;
  box-shadow: $krino-boxShadow;
  display: flex;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-y: auto;
  gap: 10px;
  padding: 10px;
  border: 1px solid $color-krino-grey2;
  border-radius: 0.4em;
}

.templatesConstructor__botCheckbox {
  display: flex;
  align-items: center;
  width: auto; /* Cambiado para permitir que el contenedor se ajuste al contenido */
  flex: 1 1 calc(33.333% - 10px); /* Distribuye los elementos de manera flexible */
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  input[type='checkbox'] {
    margin-right: 8px;
  }

  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block; /* Cambiado a block para ocupar el ancho completo disponible */
  }
}



.templatesConstructor__contructorSection {
  border: $color-krino-grey2 solid 1px;
  border-radius: 0.4em;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-shadow: $krino-boxShadow;
  position: relative;

  .infoMessage {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-120%);
    color: $color-primary;
    font-weight: 500;
    font-size: 0.9em;
  }

  header {
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: $color-krino-grey2 solid 1px;

    input {
      text-align: center;
      color: $color-primary;
      margin: 0;
      background-color: transparent;
      border: 0;
      border-bottom: 0.05em solid $color-blue-secondary;
      font-weight: 500;
      font-size: 1.2em;
      padding: 0.3em;

      &:focus {
        border: 0;
        outline: $color-blue-secondary solid 0.05em;
        border-radius: 0.4em;
      }
    }
  }

  textarea {
    font-size: 1.1em;
    padding: 0.3em;
    resize: none;
    border: 0;
    width: 100%;
    height: 100%;
    outline: none;
  }

  .footerInfo {
    border-top: $color-krino-grey2 solid 1px;

      p {
        margin: 0.05em 0;
        color: $color-primary;
      }

      span {
        color: $color-blue-secondary;
        margin: 0 0.25em;
        cursor: pointer;
        position: relative;

        .imgPreview {
          position: absolute;
          bottom: 0;
          left: 0;
          min-width: 30em;
          max-width: 70vw;
          max-width: 50vh;
          background-color: white;
          border-radius: 0.4em;
          box-shadow: $krino-boxShadow;
          padding: 0.5em;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            max-width: inherit;
            max-height: inherit;
          }
        
          button {
            margin-top: 2em;
            color: white;
          }

          .inputFileName {
            border: none;
            width: 80%;
            text-align: center;
            outline-color: $color-primary;
          }
        }
      }
  }

  footer {
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .emojiPannel {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(0%, -100%);
    }

    .leftButtons .footerButton {
      &:first-child {
        margin-left: 0;
      }

      margin: 0 0.5em;
    }

    .footerButton {
      border: $color-krino-grey2 solid 1px;
      background-color: transparent;
      padding: 0.5em;
      border-radius: 0.4em;

      img {
        height: 0.9em;
        width: 0.9em;
      }
    }

    .footerButton:hover {
      background-color: $color-krino-grey;
      box-shadow: $krino-boxShadow;
    }
  }
}

.templateContructor__exampleModal {
  .templateContructor__exampleModal__title {
    color: $color-primary;
  }

  .templateContructor__exampleModal__description {
    color: $color-blue-secondary;
    font-weight: 500;
  }

  .templateContructor__exampleModal__template {
    box-shadow: $krino-boxShadow;
    padding: 1em 0.5em;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    border-radius: 0.5em;

    input {
      border-radius: 0.4em;
      border: $color-krino-grey2 solid 0.05em;
      text-align: center;
      outline-color: $color-primary;
    }
  }

  button {
    margin: 0 auto;
    display: block;
  }
}
