.selectChannel__title {
  color: $color-krino-grey2;
}

.selectChannel__section {
  width: 80%;
  display: flex;
  justify-content: space-evenly !important;
  margin: 2em auto;

  img {
    width: 7em;
    margin: 1em 0;
    cursor: pointer;
    transition: transform 0.3s ease-in;
    transform: scale(1);
    display: block;
  }

  .selectChannel__section__imgContainer {
    display: block;
    p {
      font-weight: 500;
      font-size: 1.1em;
      margin: 0.2em;
    }
  }

  .selected {
    background-color: rgba($color: $color-primary, $alpha: 0.2);
    border-radius: 50%;
  }

  img:hover {
    transform: scale(1.1);
  }
}

.channelContainer {
  margin: 2em;
  width: 60vw;
  // max-height: 26em;
  overflow: auto;
} 

.channelContainer--facebook {
  div {
    flex-direction: column;
    font-size: 1.3em;
    justify-content: space-between;

    p {
      font-weight: 500;
    }

    p:first-child {
      align-self: flex-start;
    }
    
    p:last-child {
      align-self: flex-end;
      color: $color-krino-grey2;
    }

  }

  .tutorial__message {
    text-align: right;
    color: $color-krino-grey2 !important;
  }

  .facebookNumbers-container {
    font-size: 0.9em;
    align-items: center;

    input {
      height: 1em;
      margin: 1em 0;
      width: 100%;
    }

    .facebookNumbers-container__group {
      background-color: rgba($color: $color-krino-grey2, $alpha: 0.3);
      border-radius: 30px;
      margin: 0.5em auto;
      width: 50%;
      padding: 1em;

      input {
        padding: 0.5em;
        border-radius: 0.4em;
      }
    }

    .facebookNumbers-container__buttons {
      font-size: 0.5em;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 1em;

      button {
        margin: 0 1em;
      }
    }
  }
}

.channelContainer--wsp {
  div {
    flex-direction: column;
    font-size: 1.2em;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;

    p {
      font-weight: 500;
    }

    p:first-child {
      align-self: flex-start;
    }
    
    p:last-child {
      align-self: flex-end;
      color: $color-krino-grey2;
    }
    
    .wspNumberContainer {
      margin: 1em auto;
      
      p, a{
        margin: 0;
        margin-bottom: 1em;
        font-size: 0.7em;
      }

      a {
        color: $color-krino-grey2;
        text-align: right;
      }

      select {
        font-size: 0.8em;
        margin: 0 auto;
        margin-bottom: 1em;
      }

      .noNumberMessage {
        margin: 2em 0;
        color: $color-primary;
      }

      .buttonsSection {
        display: block;
        width: 100%;
        
        button {
          margin-bottom: 1em;
          display: block;
          background-color: $color-primary;
          width: fit-content;
          font-size: 0.8em;
          margin-left: auto;
        }
      }
    }
  }
}

.channelContainer--web {

  & > div:first-child {
    display: block;
    // padding-top: 16em;
    height: 100%;
    width: 100%;
  }

  article {
    margin: 1em 0 ;
    width: 100%;
  }

  .code {
    width: 80%;
    margin: 0 auto;
    background-color: $color-dark;
    color: $color-krino-white;
    padding: 1em;
    border-radius: 1em;
    font-size: 1.1em;
    overflow-wrap: anywhere;
  }

  .titleContainer {
    flex-direction: row;
    align-items: center;

    p {
      margin: 0;
    }

    h2 {
      position: relative;
      margin: 1em 0.5em;

      &::after {
        content: "";
        border: 3px solid $color-primary;
        width:  1.2em;
        height: 1.2em;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }


  }
}

.wspButtonMain {
  width: 100%;
  height: 100%;
  // max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;


  .wspButtonMain__title{
    text-align: center;
    color: $color-primary;
    margin-bottom: 1em;
  }

  .wspButtonMain__section {
    margin-bottom: 2.5em;
    h5 {
      text-align: center;
      color: $color-primary;
      font-size: 1.6em;
    }

    p {
      font-weight: 500;
      text-align: center;
    }

    .wspButtonMain__section__buttonContainer {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-evenly;
    
      button {
        width: 9em;
      }
    }

    .wspButtonMain__section__ColorSelector {
      margin: 1.5em 0;

      p {
        margin: 0;
        margin-bottom: 1em;
      }

      input[type='color'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 7em;
        height: 5em;
        border: none;
        cursor: pointer;
        border-radius: 0.4em;
        padding: 0.6em;
        background: $color-krino-grey2
      }

      input[type='color']::-webkit-color-swatch,  input[type='color']::-moz-color-swatch{
        border-radius: 0.4em;
        border: none;
      }
    }

    img {
      max-width: 80%;
      margin: 0.7em auto;
      display: block;
      box-shadow: $krino-boxShadow;
      border-radius: 10px;
    }

    .wspButtonMain__TextInput {
      padding: 1em;
      margin: 0 auto;
      border-radius: 1em;
      border: 0;
      box-shadow: $krino-boxShadow;
      width: 90% !important;
    }

    .code {
      width: 95%;
      margin: 0 auto;
      background-color: $color-dark;
      
      padding: 1em;
      border-radius: 1em;
      font-size: 1.1em;
      overflow-wrap: anywhere;
      margin-bottom: 1em;

      p {
        margin: 0;
        color: $color-krino-white;
      }
    } 
  }
}