.DropDownButton {
  border-radius: 0.4em;

  button {
    border:0;
    padding: 0.2em;
    background-color: transparent !important;
    color: $color-dark !important;


    &:hover, &:focus {
      background: transparent !important;
      color: $color-dark !important;
    }
  }
}


.sectionMenus {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .sectionMenus__content {
    flex-grow: 1;
    max-height: calc(100% - 6.3em);

    .tab-content, .tab-pane {
      height: 100%;
      width: 100%;
      // max-height: inherit;
    }
  }

}