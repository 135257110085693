.FaqMessageContainer--viewOnly {
  .pdf {
    min-width: fit-content;
    white-space: nowrap;
  }

  .FaqMessage {
    width: 100%;
  }

  .messageBox {
    box-shadow: $krino-boxShadow;
  }
}

.FaqButtonContainer--viewOnly {
  .ButtonsList {
    flex-direction: column;
    width: 100%;
  }

  .ButtonsList li {
    margin: 0 !important;
    width: 100%;
  }

  .ButtonsList li:hover {
    border-bottom: 0.2em solid $color-blue-secondary;
    background-color: #dddddd;
    cursor: auto;
  }

  .FaqButton {
    margin: 1em auto;
  }
}

.sendToCrmContainer--viewOnly {
  p {
    text-align: center;
    font-size: 1.2em;
    margin: 1em 0;
    font-weight: 500;
  }
}

.viewOnlyLiveChatCard {
  margin: 1em 0;
  font-weight: 500;
  color: $color-blue-secondary;
  font-size: 1.2em;
}

.awaitCardTitle {
  text-align: center;
  font-weight: 500;
  color: $color-blue-secondary;
  font-size: 1.3em;
}

.FaqButton--ViewOnly {
  width: 60%;
  justify-content: space-between;
  
  .FaqButton__metrics {
    p {
      margin: 0;
      font-weight: 500;
      color: $color-blue-secondary;
      font-size: 1.4em;
    }
  }

}
