.poweredByKrino_app {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .poweredByKrino__section {
    position: relative;
    width: 25%;
    bottom: 10%;
    min-width: 350px;

    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    
    .poweredByKrino__img {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      // background-color: $color-primary;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50%;
      }
    }

    .poweredByKrino__message {
      font-size: 2vw;
      font-size:calc(23px + 1.5vw);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -0.4em;
      color: $color-blue-secondary;
      white-space: nowrap;
    }
  }
} 
