// table
.conversationsTable__main {
  padding: 1em;
  width: 90%;
  margin: 1em 0;
  overflow: auto;

  header {
    margin: 1em 0;
  } 
  
  select {
    margin-bottom: 1em;
  }
}


// history
.conversation__main {
  width: 85%;
  margin: 2em auto;
  position: relative;


  .conversation__title {
    color: white;
  }

  .conversation__description {
    color: white;
    margin-bottom: 2em;
  }

  .conversation__loadingContainer {
    padding: 2em;
    text-align: center;
  }
}

.conversation__section {
  position: relative;
  height: fit-content;
  
  .chat {
    border: 0 !important;
  }
}
