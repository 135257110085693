.testBot__main {
  position: relative;
  width: 100%;

  .testBot__title {
    color: white;
    text-align: center;
    margin: 2em 0;
  }

  
  .testBot__ButtonContainer {
    text-align: center;

    a {
      margin: 0 1em;
      background-color: white;
      color: $color-blue-secondary !important;
      font-size: 1.4em;
    
      &:hover {
        box-shadow: $krino-boxShadow;
      }
    }
  }

  .testBot__keypadSection {
    margin: 3em auto;
    width: 25%;
    animation-fill-mode: forwards;
    animation: appear 0.5s ease-in-out;

    .testBot__keypadSection__title {
      text-align: center;
      color: white;
    }

    .testBot__keypadSection__list {
      padding: 0;
      list-style: none;

      li {
        width: 100%;
    
        button {
          margin: 0 1em;
          background-color: white;
          color: $color-blue-secondary !important;
          font-size: 1em;
          font-weight: 500;
          border-radius: 0.4em;
          display: block;
          margin: 1em auto;
        
          &:hover {
            transform: scale(1.05);
            box-shadow: $krino-boxShadow;
          }
        }
      }
    }
  }
}

.developkeypadButtons {
  margin: 3em auto;
  width: 25%;
  animation-fill-mode: forwards;
  animation: appear 0.5s ease-in-out;

  button {
    margin: 0 1em;
    background-color: white;
    color: $color-blue-secondary !important;
    font-size: 1em;
    font-weight: 500;
    border-radius: 0.4em;
    display: block;
    margin: 1em auto;
  
    &:hover {
      transform: scale(1.05);
      box-shadow: $krino-boxShadow;
    }
  }
}


.developKeypad {
  padding: 0.5em 1em;
  max-width: 50vw;
  .developKeypad__title {
    color: $color-primary;
    text-align: center;
  }

  .developKeypad__section {
    margin: 1.3em 0;
    width: 100%;
    box-shadow: $krino-boxShadow;
    border-radius: 0.4em;

    .developKeypad__section__title {
      color: $color-primary;
      margin-bottom: 0.5em;
        
      span {
        margin-left: 1em;
        color: $color-blue-secondary;
        font-size: 0.9em;
      }
    }

    .developKeypad__section__infoChat, .developKeypad__section__context {
      padding: 1em;

      ul {
        padding: 0;
        list-style: none;
        
        li {
          display: flex;
          align-items: center;
          max-width: 30em;
          margin: 0.5em 0;
          p {
            margin: 0;
            font-weight: 500;
            font-size: 1.1em;
            
            span {
              color: $color-blue-secondary;
              font-weight: 800;
            }
          }
        }
        
      }
    }

    .developKeypad__section__context {
      max-height: 20em;
      overflow-y: auto;
    }

    .developKeypad__section__logs {
      max-height: 30em;
      overflow-y: auto;

      ul {
        list-style: none;
        padding: 0;

        li {
          max-width: 30em;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          justify-content: space-between;
          margin: 0.5em;
          padding: 0.5em 0.2em;
          box-shadow: $krino-boxShadow;
          border-radius: 0.4em;

          p {
            max-width: 90%;
            text-overflow: ellipsis;
            margin: 0;
            font-weight: 500;
            color: $color-blue-secondary;
            overflow: hidden;
          }
        }

        .developKeypad__section__logs__item--success {
          background-color: #06C16522;
        }

        .developKeypad__section__logs__item--error {
          background-color: #cc3e3422;
        }

        .developKeypad__section__logs__item--info {
          background-color: #1597E522;
        }
        
        .developKeypad__section__logs__buttonSection {
          display: flex;
          align-items: center;
          justify-content: center;
        }

      }

      .developKeypad__section__logs_no_logs_Message {
        text-align: center;
        color: $color-blue-secondary;
        font-weight: 500;
      }

    }

    .developKeypad__section__addcontext {
      padding-bottom: 1em;
      form {
        input {
          margin: 1.2em auto;
        }

        button {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  .developKeypad__copyButton {
    border: 0;
    background-color: transparent;
    color: $color-primary;
    cursor: pointer;
    border-radius: 0.4em;
    padding: 0.5em;
    margin-left: 0.5em;

    &:hover {
      background-color: $color-primary;
      color: white;
    }
  }
}

.chatBubble {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
  width: 24em;
  height: 24em;
  background-color: $color-krino-white;
  border-radius: 50%;
}


@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}