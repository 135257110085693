.ecrUserFormModal {
  padding: 1em;

  .ecrUserFormModal__title {
    margin: 1em 0;
    color: $color-primary;
    text-align: center;
  }

  .ecrUserFormModal__form {
    label {
      width: 90%;
      margin: 2.5em auto;
      display: block;

      p {
        color: $color-blue-secondary;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0.5em;
      }

      input {
        text-align: center;
        margin: 0 auto;
        width: clamp(320px, 60%, 100%);
      }
    }

    button {
      display: block;
      margin: 0 auto;
    }
  }
}