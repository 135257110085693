
.wizard__main {
  // position: absolute;
  // top: 50%;/
  // left: 50%;
  // transform: translate(-50%, -50%);
  height: 100vh;
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;


  .wizard__main__title {
    // margin-top: -4em;
    margin-bottom: 1em;
    text-align: center;
    color: $color-blue-secondary;
  }

  .wizard__main__section {
    width: 80%;
    height: 60%;
    margin: 0 auto;
    flex-grow: 1;
    padding: 2em;
    text-align: center;
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .newCampaign__main__section__subTitle {
      font-size: 1.2em;
      margin: 0;
    }
  }

  .buttons__container {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 7em;
      margin: 0.5em;
      border: 0;
    }
  }
}