.temperatureLeadModel {
  padding: 1em;
  width: 100%;

  .temperatureLeadModel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    .temperatureLeadModel__title {
      color: $color-blue-secondary;
      font-size: 2.4em;
    }

    .temperatureLeadModel__description {
      color: $color-primary;
      font-weight: 500;
    }

  }

  .temperatureLeadModel__modelList {
    padding: 0;
    list-style: none;
  
    .temperatureLeadModel__NoData {
      text-align: center;
      color: $color-blue-secondary;
      margin-top: 4em;
    }

    .temperatureLeadModel__modelList__item {
      box-shadow: $krino-boxShadow;
      display: flex;
      justify-content: space-between;
      align-items: center;      
      padding: 1em 0.3em;
      cursor: pointer;
      border-radius: 0.4em;

      &:hover {
        background-color: rgba($color: $color-primary, $alpha: 0.1);
        transform: scale(1.005);
      }

      &:not(:last-child) {
        margin-bottom: 1em;
      }

      .infoSection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        h4, h6, p {
          margin: 0;
        }

        .infoSection__primary {
          h4 {
            font-size: 1.4em;
            margin-bottom: 0.3em;
          } 
        }

        .infoSection__secondary {
          text-align: right;

          h6 {
            color: $color-blue-secondary;
            margin-bottom: 0.3em;
          }

          .active {
            color: $color-success;
            font-weight: 500;
          }

          .inactive {
            font-weight: 500;
            color: $color-danger;
          }
        }
      }

      .dropdown__container {
        background-color: transparent;
        // position: absolute;
        top: 50%;
        right: 0.5em;
        // transform: translateY(-50%);

        .dropdown__container__icon {
          color: $color-blue-secondary;
          width: fit-content;
          height: fit-content;
          padding: 0;
        }

        // button[aria-expanded=false] {
          // background-color: transparent;
          // padding: 0;
          // border: none;
          // width: fit-content;
          // height: fit-content;
          // box-shadow: none;
          
        // }
        // div[role="menu"] {
        //   z-index: 2;
        //   background-color: white;
        // }
      }
    }
  }

  .createModelButton {
    margin: 0 auto;
    display: block;
  }
}

.temperatureLeadModel__Spinner {
  text-align: center;
  margin: 4em 0;
}

// config

.temperatureLeadModelConfig__prompt {
  display: flex;
  flex-direction: column;
}

.temperatureLeadModelConfig {
  width: 100%;

  header {
    width: 100%;
    height: 7.5em;
    background-color: $color-blue-secondary;
    box-shadow: $krino-boxShadow;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 0 1em;
    text-align: center;

    .backButton {
      border: 0;
      background-color: transparent;
      color: white;
    }

    p {
      margin: 0;
    }

    .editsection {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        border: 0;
        background-color: transparent;
        color: white;
      }
    }

    .editsection, .backButton {
      min-width: 120px;
    }

  }

  main {
    flex-grow: 1;
    width: 100%;
    padding: 1em 0;
    // padding-bottom: 10em;

    .leadControlPanel__varsMain__title {
      text-align: center;
      color: $color-blue-secondary;
    }

    .newVarButton {
      display: block;
      margin: 3em auto;
    }

    .leadControlPanel__varsMain__table {
      width: 100%;
      table-layout: fixed;
      text-align: center;

      thead {
        h6 {
          text-align: center;
          color: $color-blue-secondary;
          text-align: center;
        }

        tr {
          border-bottom: 0.04em solid  rgba($color: $color-krino-grey2, $alpha: 0.6);
        }
        
        th {
          padding: 1em;
        }
      }

      tbody {
        tr {
          border-bottom: 0.04em solid  rgba($color: $color-krino-grey2, $alpha: 0.6);
        }

        .actionField {
          display: flex;
          justify-content: center;
          align-items: center;
        
          .spacer, .dropdown__container {
            min-width: 25px;
            margin-left: auto;
          }
        }

        td {
          font-weight: 500;
          padding: 1.2em;
          p {
            font-size: 1.1em;
            margin: 0;
          }

        }
      }
      
      .leadControlPanel__varsMain__newItem {
        select, input {
          border-radius: 0.4em;
          box-shadow: $krino-boxShadow;
          border: 0;
          padding: 0.4em 0.8em;
          text-align: center;

          option {
            text-align: left;
          }
        }

        .leadControlPanel__varsMain__newItem__originContainer {
          display: flex;
          align-items: center;
          justify-content: center;
    
          select:last-child {
            margin-left: 1em;
            max-width: 50%;
          }
        }
      }

      .newMetadataButton__container{
        position: relative;

        .newMetadataButton {
          position: absolute;
          left: 50%;
          bottom: -4em;
          transform: translateX(-50%);
        }
      }


    }

    .metadataStatus {
      background-color: $color-krino-success;
      color: white;
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1em 2em;
      width: calc(100% - 100px);

      h5 {
        margin: 0;
      }
    }

    .metadataStatus--error {
      background-color: $color-danger;
    }
  }
}
.permissions-table-container {
  margin: 0 !important;
  padding: 0 !important;
}

.permissions-table-container table {
  margin: 0 !important;
  padding: 0 !important;
}

#permissionsTable_wrapper {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important; /* Asegúrate de que la tabla ocupe el ancho completo */
}

#permissionsTable {
  width: 100% !important; /* Forzar el ancho completo */
  margin: 0 !important;
  padding: 0 !important;
}