.botNode {
  padding: 1.2em;
  padding-bottom: 1.7em;
  background-color: $color-krino-white;
  border: 0.15em solid $color-blue-secondary;
  border-radius: 2em;
  font-size: 0.5em;

  .selectCampaing {
    font-size: 1em;
    margin: 1.1em auto;
    display: block;
    padding: 1em;
    max-width: 100%;
    width: 100%;

    option {
      max-width: 10em;
      font-size: 2em;
      overflow-wrap: anywhere;
      padding: 0.5em;
    }
  }

  .faqHandle {
    width: 100%;
    height: 100%;
    border-radius: 2em;
    background-color: transparent;
    z-index: 10;
    border: 0;
    margin-top: 0.5em;

    .faqHandle__button {
      border-radius: 2em;
      border: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      padding: 0;

      &:hover {
        box-shadow: 0px 0px 40px -5px rgba(21,151,229,1);
      }

      &:disabled {
        box-shadow: 0px 0px 40px -5px rgba(207, 85, 69, 0.555);
      }
    }
  }

  .title {
    font-size: 2.5em;
    text-align: center;
    color: $color-primary;
  }

  .description {
    font-size: 1.5em;
    text-align: center;
    color: $color-primary;
    font-weight: 500;
  }

  .inputFaqTitle {
    font-size: 2.5em;
    text-align: center;
    color: $color-primary;
    padding: 0;
    border: 0.1em solid $color-primary;
    border-radius: 0.4em;
    margin: 0 auto;
    display: block;

    &:focus {
      outline-color: $color-primary;
    }
  }

  .section--shadow { 
    box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.4);
    border-radius: 1.5em;
    padding: 1em;
    margin: 2em 0;
    width: 100%;
  }

  .validateTime__fieldContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    &:not(:last-child) {
      margin-bottom: 1em;
    }

    p {
      margin: 0;
      margin-right: 0.5em;
      font-weight: 500;
    }
  }

  button {
    border: 0;
    background-color: transparent;
  }

  .faqHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .deleteButton {
    color: $color-danger; 
    display: block;
  }

  .plusButton {
    margin: 0 auto;
    display: block;
    color: $color-blue-secondary;
  }

  .FaqMessage, .FaqButton {
    align-items: center;
    margin: 2em 0;

    textarea {
      padding: 0.5em;
      border-radius: 1em;
    }

    .buttonsList {
      display: flex;
      justify-content: flex-end;
    }
  }

  .faqMessage__warning {
    margin: 1em 0;
    color: $color-danger;
    font-weight: 500;
    font-size: 1.2em;
  }

  .FaqButton {
    display: flex;
    align-items: center;
    .arrorConTainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .FaqButton__text {
      overflow-wrap: anywhere;
      background-color: $color-primary;
      color: white;
      padding: .4em 1em;
      border-radius: 2em;
      margin: 0;
      margin-left: 0.3em;
    
      textarea {
        background-color: $color-primary;
        color: white;
        outline: 1.2px solid rgba($color: white, $alpha: 0.4);
        border: 0;
        margin-bottom: 1em;
      }

      p {
        margin: 0;
        font-weight: 500;
      }

      input {
        margin-top: 5px;
        border-radius: 1em;
        background-color: $color-primary;
        color: white;
        outline: 1.2px solid rgba($color: white, $alpha: 0.4);
        border: 0;
        margin-bottom: 1em;
        padding: 0.5em;
      }

      textarea:focus, input:focus {
        outline: 1.2px solid rgba($color: white, $alpha: 1);
      }

    }
  }

  .FaqMessageContainer, .FaqButtonContainer, .connectFaqContainer, .sendToCrmContainer, .faqElementContainer {
    box-shadow: $krino-boxShadow;
    margin: 1em auto;
    width: 90%;
    background-color: $color-krino-white;
    padding: 1em;
    // border-radius: 30px;
    border-radius: 1.5em;

    .FaqMessageContainer__title, .FaqButtonContainer__title, .connectFaqContainer__title, .title {
      color: $color-primary;
      margin: 0;
      text-align: left;
    }

    .description {
      color: $color-blue-secondary;
      font-weight: 500;
      font-size: 1em;
      text-align: left;
      margin: 0.5em 0;
    }

    .timeSelectorContainer {
      display: flex;
      align-items: center;

      input {
        margin: 0 0.5em;
      }

      p {
        font-weight: bold;
        margin: 0;
      }
    }
  }

  .sendToCrmContainer {
    padding: 2em 1em;
    select {
      width: 80%;
      margin: 2em auto;
      font-weight: 500;

      option {
        font-size: 1.8em;
      }
    }
  }

  .FaqMessageContainer {
    .FaqMessage__inputContainer {
      position: relative;
      width: 100%;
    }

    .FaqMessage__inputContainer__errorMessage {
      position: absolute;
      top: -1em;
      color: $color-danger;
    }
  }

  .FaqMessageContainer--disabled {
    textArea {
      outline: 1.2px solid rgba($color: $color-danger, $alpha: 0.4) !important;
    }
  }

  .connectFaqContainer {
    text-align: center;
    position: relative;

    .connectFaqContainer__info {
      color: $color-blue-secondary;
      font-weight: 500;
      text-align: left;
      margin: 1em;
      font-size: 1.2em;
    }
  }

  .FaqMessage {
    display: flex;
    
    .messageBox {
      // background-color: $color-krino-white;
      background-color: rgba($color: $color-krino-grey2, $alpha: 0.2);
      padding: 2em;
      border-radius: 2em;
      font-weight: 500;
      width: 100%;
      white-space: pre-wrap;
      overflow-wrap: anywhere;
    
      .MessageBox__imgContainer {
        max-width: 80%;
        display: flex;
        align-items: center;

        .pdf {
          font-size: 1.5em;
        }

        img {
          max-width: 100%;
          aspect-ratio: 1 / 1;
        }
      }
    }

    .faqMessage__editContainer {

      textarea {
        outline: 1.2px solid rgba($color: $color-primary, $alpha: 0.4);
        // padding: 1em;
      }

      textArea:focus {
        outline: 1.2px solid rgba($color: $color-primary, $alpha: 1);
      }
    }

    // .dropdown {
    //   button {
    //     background-color: transparent;
    //     color: $color-blue-secondary;
    //   }

    //   button:hover, button:active {
    //     background-color: $color-light-grey !important;
    //   }
    // }
  }

  .FaqButton h6 {
    margin: 0;
    margin-right: 0.3em;
    width: 1em;
    text-align: center;
  }

  .handleMarker {
    margin-right: 1em;
    border-radius: 100%;
    width: 1em;
    height: 1em;
    content: "";
    background-color: $color-danger;
  }

  .trashIcon {
    color: $color-danger;
    font-size: 17px;
  }

  .elementsList {
    list-style: none;
    padding: 0;
    padding-top: 1em;
    
    .mediaAdd {
      display: flex;
      align-items: center;
      justify-content: center;
      // gap: 1em;

      .krino__button {
        background-color: $color-primary;
        margin-left: 1em;
        padding: 1em 1em;
      }
    }

    .uploadNewImg__container {
      .uploadNewImg__container__title {
        color: $color-primary;
        text-align: center;
        margin: 1em 0;
      }

      .uploadNewImg__container__errorMessage {
        text-align: center;
        font-weight: 500;
        font-size: 1.1em;
        color: $color-danger;
      }

      .uploadNewImg__container__description {
        text-align: center;
        font-weight: 500;
        font-size: 1.3em;
      }

      .inputContainer {
        margin: 1.5em 0;
        padding-left: 3em;
        .inputContainer__description {
          color: $color-primary;
          margin: 0;
          margin-bottom: 0.3em;
          font-weight: 500;
        }
      }

      .inputContainer--flex {
        display: flex;
        align-items: center;
      }

      input {
        width: 80%;
        border-radius: 1em;
        padding: 1em;
        outline: 1.2px solid rgba($color: $color-primary, $alpha: 0.4);
        border: 0;
        
      }

      input:focus {
        outline: 1.2px solid rgba($color: $color-primary, $alpha: 1);
      }
    }

    .selectedImgContainer {
      max-width: 80%;
      display: flex;
      align-items: center;
      width: fit-content;
      
      img {
        max-width: 100%;
        aspect-ratio: 1 / 1;
      }
    }

    li {


      .selectedImgContainer {
        border: 1.2px solid rgba($color: $color-primary, $alpha: 0.4);
        border-radius: 8px;
        overflow: hidden;
        margin: 0 auto;
        display: block;
      }
      .uploadNewImg__buttons {
        max-width: 100%;
        display: flex;
        justify-content: center;

        button {
          margin: 0 0.5em;
        }

        .krino__button {
          background-color: $color-primary;
        }
        .krino__button--error {
          background-color: $color-danger;
        }
      }

      .uploadNewImg__loading {
        display: block;
        font-size: 1.3em;
        font-weight: 500;
        text-align: center;
        width: 100%;
        margin: 1em auto;
      }
    }

    li:not(:last-child) {
      margin-bottom: 1em;
    }

    li:last-child:not(.mediaConfig) {
      display: flex;
      align-items: center;
      
      textArea {
        border-radius: 20px;
        border: 0;
        box-shadow: $krino-boxShadow;
        outline: 1.2px solid rgba($color: $color-primary, $alpha: 0.4);
        padding: 1em;
      }

      textArea:focus {
        outline: 1.2px solid rgba($color: $color-primary, $alpha: 1);
      }

      .button__container {
        display: flex;
        align-items: center;
        

        button {
          background-color: $color-primary;
          margin-left: 1em;
          padding: 1em 1em;
        }  
      }
    }

    .updateContainer__mediaConfig {
      padding: 0;
      margin: 3em 0;
    }

    .mediaConfig {
      display: block;
    }

  }

  .ButtonsList {
    list-style: none;
    padding: 0;
    padding-top: 1em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    li:not(:last-child) {
      margin-bottom: 1em;
      margin-right: 1em;
    }

    
  }

  .FaqButtonContainer__inputSection {
    display: flex;
    align-items: center;
    position: relative;

    .errorMessage {
      position: absolute;
      top: -1em;
      color: $color-danger;

    }

    .button__container {
      display: flex;
      align-items: center;
      
      button {
        background-color: $color-primary;
        margin-left: 1em;
        padding: 1em 1em;
        height: fit-content;
      }  
    }

    textArea, input {
      min-height: 25px;
      border-radius: 20px;
      border: 0;
      box-shadow: $krino-boxShadow;
      outline: 1.2px solid rgba($color: $color-primary, $alpha: 0.4);
      padding: 1em;
    }

    textArea:focus, input:focus {
      outline: 1.2px solid rgba($color: $color-primary, $alpha: 1);
    }

    .newButtonValueInput {
      height: 100%;
      max-width: 35%;
      margin-left: 1em;
    }
  }

  .FaqButtonContainer__inputSection--disabled {
    textArea {
      outline: 1.2px solid rgba($color: $color-danger, $alpha: 0.4);
    }
  }

  emoji-picker {
    z-index: 100;
    position: absolute;
    top: 6em;
    left: -1.5em;
    max-width: 25em;
    --input-font-size: 0.6em;
    --category-emoji-size: 0.5em;
    --emoji-size: 1.3em;
    --num-columns: 4;
    --background: white;
  }

  .elementsModal_container {
    display: block;
    font-size: 0.5em;
    margin-top: -2em;
    margin-bottom: 1em;
  }
}

.botNode--faq {
  width: 60em;
}

.botNode--connectFaq {
  width: 60em;
}

.botNode--faq--selectable:hover {
  box-shadow: 0px 0px 40px -5px rgba(21,151,229,1);
}

.botNode--liveChat {
  width: 40em;  

  .botNode--liveChat__container {
    p {
      font-size: 1.2em;
      margin: 0;
      margin-top: 0.5em;
    }
  }
}

.botNode--validaDay {
  form {
    display: flex;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
    flex-wrap: wrap;
    width: 25em;
    justify-content: center;
  
    label {
      margin: 0.5em;
      display: flex;
      align-items: center;
      
      input {
        margin-right: 0.2em;
      }
    }
  }
}

.botNode--validation {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;

    select {
      margin: 1em;
      padding: 0.5em;
      text-align: center;
      font-weight: 500;

      option {
        font-size: 2em
      }
    }

    input {
      border-radius: 1em;
      border-width: 0.3em;
      border: 0.1em solid grey;
      padding: 0.5em;
    }
  }
}

.botNode--carrousel {
  min-width: 60em;
  
  .carrousel__list {
    padding: 0;
    list-style: none;

    .carrousel__item {
      background-color: $color-krino-grey;
      margin-bottom: 1em;
      padding: 0.5em;
      border-radius: 30px;
      text-align: center;

      .carrousel__item__container {
        
        &:nth-child(2) {
          button:first-child {
            color: $color-blue-secondary;
          }

          button:last-child {
            color: $color-danger;
          }
        }

        .carrousel__item__title {
          white-space: break-spaces;
          overflow-wrap: anywhere;
          color: $color-blue-secondary
        }

        img {
          max-width: 80%;
        }

        .carrousel__item__description {
          white-space: pre-wrap;
          font-size: 1.4em;
          color: $color-blue-secondary;
          font-weight: 500;
          margin: 1em 0;
        }
      }
      &:last-child {
        button {
          background-color: transparent;
          display: block;
          margin: 0 auto;
          color: $color-primary;
        }
      }
    }
  }
}

.botNode--note {
  z-index: 4;
  border-radius: 0.5em;
  border: none;
  padding: 0;
  width: 30em;
  background-color: #fff9b2;
  box-shadow: $krino-boxShadow;

  .faqHeader {
    background-color: #f7f298;
    padding: 0.5em 1em;
    

    .faqHeader__colorPickerContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      .faqHeader__colorPicker {
        border-radius: 50%;
        width: 15px;
        height: 15px;
        // box-shadow: $krino-boxShadow;
        border: 0.5px solid rgba(0, 0, 0, 0.322);
  
        &:first-child {
          background-color: #fff9b2;
          margin-right: 1em;
        }
        &:last-child {
          background-color: #ffbeb2;
        }
      } 
    }
  }

  .noteContainer {
    padding: 1em;
    width: 100%;
    .noteContainer__title {
      background-color: inherit;
      width: 100%;
      font-size: 2.3em;
      text-align: center;
      border: none;
      border-bottom: 1px solid black;
    }

    .noteContainer__description {
      // resize: none;
      padding: 0.5em;
      font-size: 1.4em;
      background-color: inherit;
      width: 100%;
      border: none;
      white-space: pre-wrap;
      min-height: 15em;
    }
  }
}

.botNode--note--red {
  background-color: #ffbeb2;

  .faqHeader {
    background-color: #f7a398;
  }
}

// ya no se usa
.botNode--question {
  h4 {
    text-align: center;  
  }

  button {
    border: 0;
    background-color: transparent;
  }

  ul {
    list-style: none;
    padding: 0;
    padding-top: 1em;
  
    li:not(:last-child) {
      margin-bottom: 1em;
    }

    li:nth-child(1) .handleMarker{
      background-color: $color-danger;
    }

    li:nth-child(2) .handleMarker{
      background-color: $color-blue-secondary;
    }

    li:nth-child(3) .handleMarker{
      background-color: rgb(58, 126, 58);
    }
  }

  .sourceHandle {
    &:nth-child(3) {
      background-color: $color-danger !important;
    }
  
    &:nth-child(4) {
      background-color: $color-blue-secondary !important;
    }
  
    &:nth-child(5) {
      background-color: rgb(58, 126, 58) !important;
    }
  }

}



.sourceHandle {
  width: 1em !important;
  height: 1em !important;
  bottom: -8px !important;
  border-radius: 1em !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.botNode--message {
  width: 22em;

  textarea {
    width: 100%;
    min-height: 15em;
    border-radius: 1em;
    padding: 0.3em;
  }
}

.subNode {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-110%);
  display: flex;
  flex-direction: column;
}

.subNode--right {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(110%);
  display: flex;
  flex-direction: column;
}



.sendData {
  box-shadow: $krino-boxShadow;
  min-width: 40em;
  font-size: 1em;
  margin-bottom: 1em;

  p {
    margin: 0.3em;
  }

  .sendData__saveButton {
      text-align: center;
      background: $color-primary !important;
      margin: 0 auto;
      margin-top: 1em;
      display: block;
    }
  
  .sendData__typeButtonContainer {
    width: 80%;
    margin: 0 auto;
    text-align: center;

    .sendData__typeButton {
      margin-bottom: 1em;
      height: 3em;
      border-radius: 0.5em;
      display: block;
      width: 100%;
      background-color: $color-krino-grey;
      border: 0;
      box-shadow: $krino-boxShadow;
      font-weight: 500;
    }

    .sendData__typeButton--selected {
      background-color: $color-primary;
      color: white;
    }
  }
  .sendData__EmailAndApiContainer {
    margin: 0 auto;
    margin-top: 2em;
    width: 80%;
    text-align: center;

    input {
      width: 100%;
      padding: 0.5em;
      border-radius: 1em;
      border: 0;
      outline: 1.2px solid rgba(21, 151, 229, 0.4);
      box-shadow: $krino-boxShadow;
    }

    select {
      width: 100%;
      padding: 0.5em;
      border-radius: 1em;
      box-shadow: $krino-boxShadow;
      border: 0;
      outline: 1.2px solid rgba(21, 151, 229, 0.4);

      option {
        font-size: 2.3em;
      }
    }
  }
  .sendData__bodyContainer {
    text-align: center;
    width: 80%;
    box-shadow: $krino-boxShadow;
    border-radius: 1em;
    margin: 0 auto;
    margin-top: 2em;

    article {
      max-width: 50em;
      margin-top: 1em;
      padding: 0.3em;
      background-color: rgba(196, 196, 196, 0.2);
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }
    button {
      background-color: $color-primary;
      margin: 1em 0;
    }
  }

}

.sendData--bubble {
  position: relative;
  min-width: unset;
  width: 5em;
  height: 5em;
  border-radius: 50%;
  margin-left: auto;

  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.sendData__modalEmailContainer {
  text-align: center;

  h3 {
    color: $color-primary;
  }

  textarea {
    width: 100%;
    height: 30em;
    padding: 1em;
    border-radius: 20px;
    outline: 1.2px solid rgba(21, 151, 229, 0.4);
    border: 0;
    resize: none;
    box-shadow: $krino-boxShadow;
  }
}



.sendData__modalApiContainer {
  text-align: center;

  h3 {
    color: $color-primary;
    margin-bottom: 0.5em;
  }

  button {
    margin-top: 0.5em;
  }

  textarea {
    width: 100%;
    height: 30em;
    padding: 1em;
    border-radius: 20px;
    outline: 1.2px solid rgba(21, 151, 229, 0.4);
    border: 0;
    resize: none;
    box-shadow: $krino-boxShadow;
  }
}

.textAreaContainer {
  textarea {
    border-radius: 20px;
    border: 0;
    box-shadow: $krino-boxShadow;
    outline: 1.2px solid rgba($color: $color-primary, $alpha: 0.4);
    padding: 1em;
  }

  textarea:focus {
    outline: 1.2px solid rgba($color: $color-primary, $alpha: 1);
  }
    
}

.faqTag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .faqTag__text {
    font-weight: 500;
    font-size: 1.2em;
    margin: 0;
  }

  .faqTag__icon {
    color: $color-primary;
  }
}

.faqTag__modal {
  text-align: center;

  .faqTag__modalTitle {
    color: $color-primary;
    margin: 0;
  }

  input {
    margin: 1em auto;

  }
}

.faq_element_info {
  // padding: 2em !important;

  .faq_element_info__title {
    color: $color-danger;
    text-align: center;
    font-size: 1.8em;
    margin-bottom: 1.3em;
  }

  .faq_element_info__button {
    background-color: $color-primary !important;
    margin: 1em auto;
    display: block;
    border-radius: 0.4em;
    font-size: 1.2em;
  }

}

.faqDataViewer {
  padding: 1em 5em!important;
  border-radius: 0.4em;


  .faqDataViewer__title {
    font-size: 5em;
    width: 100%;
    text-align: center;
    color: $color-primary;
  }

  .faqDataViewer__body {
    padding: 2em 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .faqDataViewer__element {
      margin: 1em;
      margin-top: 0em;
      border-radius: 0.4em;
      padding: 0.7em;
      font-size: 4em;

      .faqDataViewer__element__titleContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-bottom: 1em;
      }

      .faqDataViewer__element__title {
        color: $color-primary;
        margin-bottom: 0;
        margin-left: 0.2em;
        text-align: center;
        font-size: 1.4em;
        font-weight: 800;
        white-space: pre;
      }

      .faqDataViewer__element__description {
        color: $color-blue-secondary;
        text-align: center;
        font-weight: 800;
        font-size: 1.7em;  
        margin: 0;
      }
    }

  }

}

.targetSectionConnections {
  .targetSectionConnections__listContainer {
    margin: 1em 0;

    .targetSectionConnections__listContainer__title {
      color: $color-primary;
      font-size: 1.6em;
      text-align: left;
      
    }

    .targetSectionConnections__list {
      padding: 0;
      list-style: none;

      .targetSectionConnections__list__item {
        width: 100%;
        box-shadow: $krino-boxShadow;
        padding: 0.8em 1em;
        border-radius: 0.4em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1em 0;

        h6 {
          margin: 0;
          font-size: 1.5em;
          color: $color-blue-secondary;
        }

        button {
          background-color: $color-primary;
        }
      }
    }
  }

  .targetSectionConnections_section_seach {
    margin: 1.8em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    

    input {
      width: 100%;
      outline: 1.2px solid rgba(21, 151, 229, 0.4);
    }
    
  }
}

.SourceSectionConnections {
  .SourceSectionConnections__listContainer {
    .SourceSectionConnections__list {
      padding: 0;
      list-style: none;
      margin: 0.5em 0;
    }

    .SourceSectionConnections__listItem {
      box-shadow: $krino-boxShadow;
      border-radius: 0.4em;

      .SourceSectionConnections__listItem__info {
        width: 100%;
        padding: 0.8em 1.5em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1em 0;
      }

      .SourceSectionConnections__listItem__sourceInfo {
        p {
          margin: 0;
          font-weight: 500;
          font-size: 1.6em;
          color: $color-blue-secondary;
        }
      }

      .SourceSectionConnections__listItem__imgContainer {
        margin: 0;
        color: $color-primary;
      }

      .SourceSectionConnections__listItem__targetInfo {
        p {
          margin-bottom: 0.2em;
          font-weight: 500;
          font-size: 1.6em;
          color: $color-blue-secondary;
        }

        small {
          display: block;
          text-align: center;
          color: $color-primary;
          font-weight: 500;
          font-size: 1em;
        }
      }

      .SourceSectionConnections__listItem__actions {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 1em 0.5em;
        border-top: 0.2em solid #20419155;

        .krino__button {
          font-size: 1em;
          background-color: $color-primary;
        }

        .krino__button--error {
          background-color: $color-danger;
        }
      }
    }
  }
}