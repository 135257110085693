.toggleButton {
  background-color: $color-krino-grey2;
  border-radius: 1em;
  height: 2.1em;
  width: 4.3em;
  display: block;
  align-items: center;
  border: 0;
  padding: 0 0.5em;
  position: relative;
  transition: background-color 0.3s ease-in-out;

  span {
    position: absolute;
    top: 50%;
    left: calc(90% - 1.3em);
    transform: translateY(-50%);
    border-radius: 50%;
    width: 1.3em;
    display: block;
    aspect-ratio: 1 / 1;
    background-color: $color-krino-white;
    transition: all 0.3s ease-in-out;
  }
}

.toggleButton--reverse {
  span {
    left: 10%;
  }
}

.toggleButton--on {
  background-color: $color-primary;
  justify-content: flex-end;

  span {
    left: 10%;
  }
}

.toggleButton--reverse.toggleButton--on {
  span {
    left: calc(90% - 1.3em);
  }
}